import React, { useEffect, useState } from "react"
import PromptService from "../services/PromptService"
import "../styles/bootstrap.min.css"
import LoginCheck from "./LoginCheck"
import DashboardSection from "./DashboardSection"
import LanguageSection from "./LanguageSection"
import TopBar from "./Topbar"
import OurValues from "../images/our-values.png"
import ToneOfVoice from "../images/tone-voice.png"
import { useTranslation } from "react-i18next"

function Dashboard() {
  const { t } = useTranslation()
  const [dashboardSection, setDashboardSection] = useState("vareverdier")
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [savePromptMsg, setSavePromptMsg] = useState("")
  const [vareverdierRows, setVareverdierRows] = useState([{ id: 1, heading: "", description: "" }])
  const [toneofvoiceRows, setToneofvoiceRows] = useState([{ id: 1, heading: "", description: "" }])
  const savePrompt = (section) => {
    if (section === "vareverdier") {
      PromptService.savePrompt(0, section, vareverdierRows, organizationID).then(() => {
        setSavePromptMsg(t("DASHBOARD.VALUES_SAVED"))
      })
    } else if (section === "toneofvoice") {
      PromptService.savePrompt(0, section, toneofvoiceRows, organizationID).then(() => {
        setSavePromptMsg(t("DASHBOARD.TONE_SAVED"))
      })
    }
  }
  useEffect(() => {
    PromptService.getPromptByType(0, "vareverdier", organizationID).then((response) => {
      setVareverdierRows(JSON.parse(response.prompt.prompt))
    })
    PromptService.getPromptByType(0, "toneofvoice", organizationID).then((response) => {
      setToneofvoiceRows(JSON.parse(response.prompt.prompt))
    })
  }, [organizationID])
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <LoginCheck />
          <main className="col-md-9 ms-sm-auto col-lg-10  px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <div className="row w-100">
                <div className="col-6">
                  <button
                    className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#sidebarMenu"
                    aria-controls="sidebarMenu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="bi bi-list"></i>
                  </button>
                </div>
                <div className="col-6"></div>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">
                  {t("GENERAL.COMPANY_INFO")} - {window.sessionStorage.getItem("organization_name")}
                </h1>
                <div className="breadcrumb">{t("GENERAL.COMPANY_INFO")}</div>
              </div>
              <div className="col-md-3 col-lg-3 p-4 ">&nbsp;</div>
              <TopBar />
            </div>
            <div className="container-fluid">
              <div className="row">
                <div
                  className={
                    dashboardSection === "vareverdier"
                      ? "fm-top-box-active fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3"
                      : "fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3"
                  }
                  align="center"
                  onClick={() => {
                    setDashboardSection("vareverdier")
                    setSavePromptMsg("")
                  }}
                >
                  <img src={OurValues} alt={OurValues} />
                  <h1>{t("DASHBOARD.OUR_VALUES")}</h1>
                </div>

                <div
                  className={
                    dashboardSection === "toneofvoice"
                      ? "fm-top-box-active fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3"
                      : "fm-top-box col-xs-12 col-sm-12 col-md-6 col-lg-2 col-lg-offset-1 m-2 p-3"
                  }
                  align="center"
                  onClick={() => {
                    setDashboardSection("toneofvoice")
                    setSavePromptMsg("")
                  }}
                >
                  <img src={ToneOfVoice} alt={ToneOfVoice} />
                  <h1>{t("DASHBOARD.TONE_OF_VOICE")}</h1>
                </div>
              </div>
            </div>
            {dashboardSection === "vareverdier" ? (
              <>
                <div className="container-fluid mt-4 vareverdier">
                  <div className="row">
                    <div className="col-12 fm-content-box p-4">
                      <DashboardSection
                        rows={vareverdierRows}
                        setRows={setVareverdierRows}
                        title={t("DASHBOARD.OUR_VALUES")}
                      />
                      <button className="btn fm-btn" onClick={() => savePrompt("vareverdier")} type="button">
                        {t("GENERAL.SAVE")}
                      </button>{" "}
                      {savePromptMsg ? savePromptMsg : ""}
                    </div>
                  </div>
                </div>
              </>
            ) : dashboardSection === "toneofvoice" ? (
              <>
                <div className="container-fluid mt-4 toneofvoice">
                  <div className="row">
                    <div className="col-12 fm-content-box p-4">
                      <DashboardSection
                        rows={toneofvoiceRows}
                        setRows={setToneofvoiceRows}
                        title={t("DASHBOARD.TONE_OF_VOICE")}
                      />
                      <button className="btn fm-btn" onClick={() => savePrompt("toneofvoice")} type="button">
                        {t("GENERAL.SAVE")}
                      </button>{" "}
                      {savePromptMsg ? savePromptMsg : ""}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="container-fluid mt-4 toneofvoice">
                  <div className="row">
                    <LanguageSection />
                  </div>
                </div>
              </>
            )}
          </main>
        </div>
      </div>
    </div>
  )
}
export default Dashboard
