import httpClient from "../utils/httpClientSetup"

class PIMServices {
  getPIMConfig(orgId) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { organization_id: orgId },
      url: process.env.REACT_APP_API_SERVER_URL + "getpimconfig",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  savePIMConfig(orgId, config) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgId,
        pim_url: config.pim_url,
        pim_username: config.pim_username,
        pim_password: config.pim_password,
        pim_structure: config.pim_structure,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "savepimconfig",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  saveExportToField(orgId, fieldDesc, fieldMeta) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgId,
        export_field_description: fieldDesc,
        export_field_meta: fieldMeta,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "saveexportfield",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }
  setPIMFirstImportStart(orgId) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { organization_id: orgId },
      url: process.env.REACT_APP_API_SERVER_URL + "setpimfirstimportstart",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  setPIMFrequency(orgID, frequency, days, time, attrIDs, checkStartImportNow, importChanged) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgID,
        frequency: frequency,
        days: days,
        time: time,
        attribute_ids: attrIDs,
        start_import: checkStartImportNow,
        import_changed: importChanged,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "setpimfrequency",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  getPIMFrequency(orgID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { organization_id: orgID },
      url: process.env.REACT_APP_API_SERVER_URL + "getpimfrequency",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  setAutoMunualApprove(orgID, autoManual) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { organization_id: orgID, move_to_feed: autoManual },
      url: process.env.REACT_APP_API_SERVER_URL + "setautoapprove",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  pimProgressList(orgID, pageNumber) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { organization_id: orgID, page_number: pageNumber },
      url: process.env.REACT_APP_API_SERVER_URL + "getpimprogresslist",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  pimAttributes(orgID) {
    const data = {
      organization_id: orgID,
    }

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data,
      url: process.env.REACT_APP_API_SERVER_URL + "pimattributes",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  resumeStopImport(resumestop, orgID) {
    const data = {
      organization_id: orgID,
      first_import: resumestop,
    }

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data,
      url: process.env.REACT_APP_API_SERVER_URL + "resumestopfirstimport",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }
}

const PIMServicesInstance = new PIMServices()
export default PIMServicesInstance
