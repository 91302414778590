import httpClient from "../utils/httpClientSetup"

class ProductService {
  productList(assistantID, action, serachISBN, page, orgID) {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url:
        process.env.REACT_APP_API_SERVER_URL +
        "products?page=" +
        page +
        "&action=" +
        action +
        "&assistant_id=" +
        assistantID +
        "&searchisbn=" +
        serachISBN +
        "&organization_id=" +
        orgID,
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  searchAndSelectISBN(query, orgID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { query: query, organization_id: orgID },
      url: process.env.REACT_APP_API_SERVER_URL + "productsisbn",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  productStats(assistantID, orgID) {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url:
        process.env.REACT_APP_API_SERVER_URL +
        "productsstats?assistant_id=" +
        assistantID +
        "&organization_id=" +
        orgID,
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  outputList(assistantID, isbn, action, orgID) {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url:
        process.env.REACT_APP_API_SERVER_URL +
        "outputs?isbn=" +
        isbn +
        "&assistant_id=" +
        assistantID +
        "&action=" +
        action +
        "&organization_id=" +
        orgID,
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  exampleList(assistantID, orgID) {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url:
        process.env.REACT_APP_API_SERVER_URL + "examplelist?assistant_id=" + assistantID + "&organization_id=" + orgID,
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  async deleteOutput(outputID) {
    const response = await httpClient.delete(`deleteOutput?outputID=${outputID}`)

    return response.data
  }

  async changeOutputExampleStatus(outputID) {
    const response = await httpClient.patch(`changeOutputExampleStatus?outputID=${outputID}`)

    return response.data
  }

  addSchedule(orgID, assistantID, selectedDate, isbnlist) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        isbns: isbnlist,
        assistant_id: assistantID,
        cron_start_date: selectedDate,
        organization_id: orgID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "addSchedule",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  saveManualOutputID(assistantID, orgID, outputID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        assistant_id: assistantID,
        organization_id: orgID,
        output_id: outputID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "savemanualoutputid",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  outputIDSavedForPush(assistantID, orgID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { assistant_id: assistantID, organization_id: orgID },
      url: process.env.REACT_APP_API_SERVER_URL + "getoutputidsavedforpush",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }
}

const productServiceInstance = new ProductService()
export default productServiceInstance
