import { Link } from "react-router-dom"
import React, { useEffect, useRef, useState } from "react"
import UserService from "../services/UserService"
import ProductService from "../services/ProductService"
import AssistantService from "../services/AssistantService"
import PromptService from "../services/PromptService"
import CreditBar from "./CreditBar"
import TopBar from "./Topbar"
import CloseIcon from "../images/close.png"
import AsyncSelect from "react-select/async"
import Information from "../images/info_outline.png"
import { useTranslation } from "react-i18next"
import CloseCircle from "../images/cross-circle.png"
import { useParsedAssistantIdFromParams } from "../utils/utils"

function ImproveJob() {
  const { assistantID } = useParsedAssistantIdFromParams()
  const { t } = useTranslation()
  const userID = parseInt(window.sessionStorage.getItem("user_id"), 10)
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [showSaveMsgButton, setShowSaveMsgButton] = useState(false)
  const [outputSavedMsg, setOutputSavedMsg] = useState(null)
  const [savePromptMsg, setSavePromptMsg] = useState("")
  const [executeMessage, setExecuteMessage] = useState(null)
  const [output, setOutput] = useState(null)
  const [isbn, setISBN] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [userPromptMessage, setUserPromptMessage] = useState("")
  const [systemdefinedString, setSystemdefinedString] = useState("")
  const [productDetails, setProductDetails] = useState("")
  const [showTestPopUpButton, setShowTestPopUpButton] = useState(false)
  const [totalCredit, setTotalCredit] = useState()
  const [creditUsed, setCreditUsed] = useState()
  const [isSeoTextEditable, setIsSeoTextEditable] = useState(false)
  const [seoOutput, setSeoOutput] = useState()

  const [questionsList, setQuestionsList] = useState()
  const [answerData, setAnswerData] = useState([])

  const handleChange = (selectedOption) => {
    setISBN(selectedOption.value)
  }

  useEffect(() => {
    PromptService.getAssistantQuestions(assistantID).then((response) => {
      setQuestionsList(response.questions)
      response.questions.forEach((question) => {
        fetchAnswers(question.id)
      })
    })

    AssistantService.getConsumedCredits(assistantID, organizationID).then((assistresponse) => {
      setTotalCredit(assistresponse.credits)
      setCreditUsed(assistresponse.consumed)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID])

  const fetchAnswers = async (questionID) => {
    try {
      PromptService.getAnswersByQuestion(questionID, organizationID).then(
        (response) => {
          setAnswerData((prevData) => ({
            ...prevData,
            [questionID]: response.answers,
          }))
          setShowTestPopUpButton(true)
        },
        (error) => {
          setShowTestPopUpButton(false)
        }
      )
    } catch (error) {}
  }

  const addRow = (questionID) => {
    setAnswerData((prevData) => {
      const currentAnswers = prevData[questionID] || []
      const updatedAnswers = [...currentAnswers, { id: "", answer_heading: "", answer: "" }]
      return {
        ...prevData,
        [questionID]: updatedAnswers,
      }
    })
  }

  const removeRow = (questionID, rowIndex) => {
    setAnswerData((prevData) => {
      const currentAnswers = prevData[questionID] || []
      const updatedAnswers = currentAnswers.filter((_, index) => index !== rowIndex)
      return {
        ...prevData,
        [questionID]: updatedAnswers,
      }
    })
  }

  const saveOutputHistory = () => {
    UserService.saveOutputHistory(isbn, output, assistantID, organizationID, answerData, questionsList).then(() => {
      setOutputSavedMsg(t("JOBS.IMPROVE.OUTPUT_SAVED"))
      setSeoOutput(output)
      setExecuteMessage(nl2br(output))
      setIsSeoTextEditable(false)
    })
  }

  const saveJobDescription = () => {
    PromptService.updateAnswers(assistantID, organizationID, answerData, questionsList).then(() => {
      setSavePromptMsg(t("JOBS.IMPROVE.UPDATED"))
    })
  }
  const openTestPopUp = () => {
    handleOpenModal()
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleGenerateSEOSubmit = (event) => {
    event.preventDefault()
    setShowSaveMsgButton(false)
    setExecuteMessage(t("JOBS.IMPROVE.IN_PROGRESS") + "...")
    setUserPromptMessage("")

    UserService.execution(organizationID, userID, assistantID, isbn, answerData, questionsList).then((response) => {
      const parsedData = JSON.parse(response.data.response)
      setExecuteMessage(nl2br(parsedData.choices[0].message.content))
      setSeoOutput(parsedData.choices[0].message.content)
      setUserPromptMessage(response.data.userPrompt)
      setProductDetails(response.data.productDetails)
      setSystemdefinedString(response.data.combinedFinalString)
      setShowSaveMsgButton(true)
      setOutput(parsedData.choices[0].message.content)
      setCreditUsed(creditUsed + 1)
    })
    setShowModal(false)
  }

  const loadOptions = (inputValue) => {
    if (!inputValue) return Promise.resolve([])
    return ProductService.searchAndSelectISBN(inputValue, organizationID).then((response) => {
      return response.productsisbns.map((item) => ({
        label: item.product_isbn,
        value: item.product_isbn,
      }))
    })
  }

  const HelpBubble = ({ text }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false)
    const bubbleRef = useRef(null)

    const toggleTooltip = () => {
      setTooltipVisible(!isTooltipVisible)
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (bubbleRef.current && !bubbleRef.current.contains(event.target)) {
          setTooltipVisible(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [])

    return (
      <div className="help-container" ref={bubbleRef}>
        <img className="help-bubble" onClick={toggleTooltip} src={Information} alt="help-bubble" />
        {isTooltipVisible && <div className="tooltip">{text}</div>}
      </div>
    )
  }
  const makeOutPutEditable = () => {
    setIsSeoTextEditable(true)
  }

  const handleSeoOutputChange = (e) => {
    setOutput(e.target.value)
    setExecuteMessage(nl2br(e.target.value))
  }
  const SystemPrompt = ({ text }) => <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br /> ") }} />

  const nl2br = (text) => {
    return text.split("\n").map((str, index) => (
      <>
        {str}
        <br />
      </>
    ))
  }
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3  mb-1">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">{t("JOBS.MANAGE_DESCRIPTIONS")}</h1>
                <div className="breadcrumb">
                  <Link to={"/" + assistantID + "/job-description"}>{t("JOBS.DESCRIPTION_FOR_ASSISTANT")}</Link>
                  &nbsp;&raquo; {t("JOBS.MANAGE_DESCRIPTIONS")}
                </div>
              </div>
              <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
              <TopBar />
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 ">
              <div className="col-md-3 col-lg-8 px-4 ">
                <p>{t("JOBS.IMPROVE.PAGE_SUBLINE")}</p>
              </div>
            </div>

            <div className="container-fluid ">
              <div className="row">
                {questionsList &&
                  questionsList.map((values, keys) => {
                    return (
                      <>
                        <div className="col-12 col-lg-6 p-4">
                          <div className="col-12 job_desc_question_label">
                            <table className="table table-hover align-middle">
                              <tbody>
                                <tr className="tr-header">
                                  <td>
                                    {values.question} <HelpBubble text={values.help} />
                                  </td>
                                  <td align="right">
                                    <button className="btn fm-btn" onClick={() => addRow(values.id)} type="button">
                                      {t("GENERAL.ADD")}
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12">
                            <div className="responsive-container" id={values.id}>
                              {answerData[values.id] &&
                                answerData[values.id].map((value, key) => (
                                  <div key={value.id} className={`row align-items-center ${value.id}`}>
                                    {values.type === "twocolumn" && (
                                      <div className=" col-11 col-lg-3">
                                        <input
                                          type="text"
                                          name={`answer_heading_${value.id}`}
                                          className="form-control"
                                          placeholder=""
                                          value={value.answer_heading}
                                          onChange={(e) => {
                                            setAnswerData((prevData) => {
                                              const updatedAnswers = [...prevData[values.id]]
                                              updatedAnswers[key].answer_heading = e.target.value
                                              return {
                                                ...prevData,
                                                [values.id]: updatedAnswers,
                                              }
                                            })
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div className={values.type === "twocolumn" ? "col-11 col-lg-8" : "col-11"}>
                                      <input
                                        type="text"
                                        name={`answer_${value.id}`}
                                        className="form-control"
                                        placeholder=""
                                        value={value.answer}
                                        onChange={(e) => {
                                          setAnswerData((prevData) => {
                                            const updatedAnswers = [...prevData[values.id]]
                                            updatedAnswers[key].answer = e.target.value
                                            return {
                                              ...prevData,
                                              [values.id]: updatedAnswers,
                                            }
                                          })
                                        }}
                                      />
                                    </div>
                                    <div className="col-1 text-end">
                                      <img
                                        className="close-btn"
                                        src={CloseCircle}
                                        title={t("GENERAL.REMOVE")}
                                        onClick={() => removeRow(values.id, key)}
                                        alt="Close"
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>

                            <table className="table d-none table-hover align-middle" id={values.id}>
                              <tbody>
                                {answerData[values.id] &&
                                  answerData[values.id].map((value, key) => {
                                    return (
                                      <>
                                        <tr key={value.id} className={value.id}>
                                          {values.type === "twocolumn" && (
                                            <td className="col-3">
                                              <input
                                                type="text"
                                                name={`answer_heading_${value.id}`}
                                                className="form-control"
                                                placeholder=""
                                                aria-label=""
                                                aria-describedby=""
                                                value={value.answer_heading}
                                                onChange={(e) => {
                                                  setAnswerData((prevData) => {
                                                    const updatedAnswers = [...prevData[values.id]]
                                                    updatedAnswers[key].answer_heading = e.target.value
                                                    return {
                                                      ...prevData,
                                                      [values.id]: updatedAnswers,
                                                    }
                                                  })
                                                }}
                                              />
                                            </td>
                                          )}
                                          <td>
                                            <input
                                              type="text"
                                              name={`answer_${value.id}`}
                                              className="form-control"
                                              placeholder=""
                                              aria-label=""
                                              aria-describedby=""
                                              value={value.answer}
                                              onChange={(e) => {
                                                setAnswerData((prevData) => {
                                                  const updatedAnswers = [...prevData[values.id]]
                                                  updatedAnswers[key].answer = e.target.value
                                                  return {
                                                    ...prevData,
                                                    [values.id]: updatedAnswers,
                                                  }
                                                })
                                              }}
                                            />
                                          </td>
                                          <td align="right" className="col-1">
                                            <img
                                              className="close-btn"
                                              src={CloseCircle}
                                              title={t("GENERAL.REMOVE")}
                                              onClick={() => removeRow(values.id, key)}
                                              alt="Close"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )
                  })}

                <div className="col-12 p-4">
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                    {showTestPopUpButton && (
                      <>
                        <button className="btn btn-m fm-btn me-md-2 px-5" type="button" onClick={openTestPopUp}>
                          {t("JOBS.IMPROVE.TEST")}
                        </button>
                        <button className="btn btn-m fm-btn me-md-2" onClick={() => saveJobDescription()} type="button">
                          {t("JOBS.IMPROVE.SAVE")}
                        </button>{" "}
                        {savePromptMsg && savePromptMsg}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-12 p-4">
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start"></div>
                </div>

                <div className="col-12 fm-content-box pt-4">
                  <div className="col-12">
                    <table width="100%" className=" align-middle">
                      <tbody>
                        {window.location.origin === "http://localhost:3000" ||
                          (window.location.origin === "http://dr-beta.virtualassistants.no:3000" && (
                            <>
                              <tr>
                                <td>
                                  <h3>{t("JOBS.IMPROVE.PROMPT")}</h3>
                                </td>
                              </tr>
                              <tr>
                                <td>{systemdefinedString && <SystemPrompt text={systemdefinedString} />}</td>
                              </tr>

                              <tr>
                                <td>{userPromptMessage && <SystemPrompt text={userPromptMessage} />}</td>
                              </tr>
                              <tr>
                                <td>
                                  <br />
                                  <hr />
                                  <br />
                                </td>
                              </tr>
                            </>
                          ))}
                        <tr>
                          <td>
                            <h3>{t("JOBS.ORIGINAL_TEXT")}</h3>
                          </td>
                        </tr>
                        <tr>
                          <td>{productDetails && <SystemPrompt text={productDetails} />}</td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                            <hr />
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h3>
                              {t("JOBS.IMPROVE.OUTPUT")}{" "}
                              {seoOutput && (
                                <>
                                  <i
                                    className="bi bi-pencil language-icon"
                                    title={t("JOBS.IMPROVE.EDIT_OUTPUT")}
                                    onClick={() => makeOutPutEditable()}
                                  ></i>
                                </>
                              )}
                            </h3>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center text-sm-start">
                            {executeMessage && (
                              <>
                                {" "}
                                {isSeoTextEditable ? (
                                  <>
                                    <textarea
                                      className="w-75 p-3 form-control"
                                      style={{ height: "200px" }}
                                      onChange={handleSeoOutputChange}
                                    >
                                      {seoOutput}
                                    </textarea>
                                  </>
                                ) : (
                                  <>{executeMessage}</>
                                )}{" "}
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {showSaveMsgButton && (
                              <>
                                <br />
                                <button
                                  className="btn btn-m fm-btn me-md-2 px-5 mt-3"
                                  type="button"
                                  onClick={saveOutputHistory}
                                >
                                  {t("JOBS.IMPROVE.SAVE_TRAINING_TEXT")}
                                </button>
                                <br />
                              </>
                            )}
                            {outputSavedMsg && outputSavedMsg} <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {showModal && (
              <div className="modal display-block">
                <div className="modal-main">
                  <div
                    style={{
                      float: "right",
                      marginTop: "-15px",
                      marginRight: "-5px",
                    }}
                  >
                    <img
                      src={CloseIcon}
                      onClick={handleClose}
                      className="close-btn"
                      width="12"
                      alt={t("GENERAL.CLOSE")}
                    />
                  </div>
                  <div className="modal-contentt">
                    <div className="col-12 p-4">
                      <div className="col-12 job_desc_question_label">{t("JOBS.IMPROVE.PRODUCTS")}</div>
                      <div className="col-12">
                        <div className="mb-3">
                          <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            onChange={handleChange}
                            defaultOptions
                            placeholder="Search and select..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 p-4">
                      <div className="col-12">
                        <button
                          className="btn btn-m fm-btn me-md-2 px-5"
                          type="button"
                          onClick={handleGenerateSEOSubmit}
                        >
                          {t("JOBS.IMPROVE.TEST")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className=" pb-4"></div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default ImproveJob
