import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react"
import "semantic-ui-css/components/icon.min.css"
import TopBar from "./Topbar"
import { useTranslation } from "react-i18next"
import { Dropdown } from "semantic-ui-react"
import PIMServicesInstance from "../services/PIMServices"
function PIMConfig() {
  const { t } = useTranslation()
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [PIMSaveMsg, setPIMSaveMsg] = useState()
  const [dailyWeeklyMonthly, setDailyWeeklyMonthly] = useState()
  const [monthDay, setMonthDay] = useState()
  const [selectedTime, setSelectedTime] = useState()
  const [saveFrequencyMsg, setSaveFrequencyMsg] = useState()
  const [attributesList, setAttributesList] = useState()
  const [selectedAttributeIDs, setSelectedAttributeIDs] = useState([])
  const [showFirstImportButton, setShowFirstImportButton] = useState(false)
  const [firstImportEnable, setFirstImportEnable] = useState("disabled")
  const [showPIMFirstStep, setShowPIMFirstStep] = useState(true)
  const [showPIMSecondStep, setShowPIMSecondStep] = useState(false)
  const [showPIMThirdStep, setShowPIMThirdStep] = useState(false)
  const [checkStartImportNow, setCheckStartImportNow] = useState(false)
  const [importChanged, setImportChanged] = useState("no")
  const [PIMProgressList, setPIMProgressList] = useState(null)
  const [moveToFeed, setMoveToFeed] = useState()
  const [exportFieldMsg, setExportFieldMsg] = useState()
  const [exportSelectedAttributeDescID, setExportSelectedAttributeDescID] = useState()
  const [exportSelectedAttributeMetaID, setExportSelectedAttributeMetaID] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [totalProgressPages, setTotalProgressPages] = useState(1)

  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  })
  const selectWeeklyDays = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      ...day,
    }))
  }
  const days = Array.from({ length: 31 }, (_, index) => index + 1)
  const handleMonthDayChange = (event) => {
    setMonthDay(event.target.value)
  }
  const ResumeStopFirstImport = (resumestop) => {
    var confirmed
    if (resumestop === "stopped") {
      confirmed = window.confirm(t("CONFIRM_WINDOW.STOP_PRODUCT_IMPORT"))
    } else {
      confirmed = true
    }

    if (confirmed) {
      PIMServicesInstance.resumeStopImport(resumestop, organizationID).then(() => {
        setShowFirstImportButton(!showFirstImportButton)
        setFirstImportEnable(resumestop)
      })
    }
  }
  const handleSelectChange = (event) => {
    setSelectedDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    })
    setDailyWeeklyMonthly(event.target.value)
  }
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0")
    return `${hour}:00`
  })
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value + ":00")
  }

  const [PIMFields, setPIMFields] = useState({
    pim_url: "",
    pim_username: "",
    pim_password: "",
    pim_structure: "",
    last_cron_date: "",
  })

  useEffect(() => {
    PIMServicesInstance.pimAttributes(organizationID).then((response) => {
      setAttributesList((prevList) => {
        if (Array.isArray(response.data)) {
          const newItems = response.data.map((values) => ({
            value: values.id,
            text: values.name,
          }))
          return [...(prevList || []), ...newItems]
        }
        return prevList || []
      })
    })

    PIMServicesInstance.getPIMConfig(organizationID)
      .then((response) => {
        let formattedDifference = "0000"
        if (response.config.last_cron_date === null || response.config.last_cron_date === "0000-00-00 00:00:00") {
        } else {
          const utcDateStr = response.config.last_cron_date
          const utcDateObj = new Date(utcDateStr + " UTC")
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }

          formattedDifference = utcDateObj.toLocaleString("en-US", options)
        }
        setPIMFields({
          pim_url: response.config.pim_url,
          pim_username: response.config.pim_username,
          pim_password: response.config.pim_password,
          pim_structure: response.config.pim_structure,
          export_field_description: response.config.export_field_description,
          last_cron_date: formattedDifference,
        })
        setExportSelectedAttributeDescID(response.config.export_field_description)
        setExportSelectedAttributeMetaID(response.config.export_field_meta)
        setMoveToFeed(response.config.move_to_feed)
        setShowPIMFirstStep(false)
        setShowPIMSecondStep(true)
      })
      .catch((error) => {
        setShowPIMFirstStep(true)
        setShowPIMSecondStep(false)
      })

    PIMServicesInstance.getPIMFrequency(organizationID)
      .then((response) => {
        setShowFirstImportButton(true)
        setDailyWeeklyMonthly(response.pimFrequency.frequency)
        setSelectedTime(response.pimFrequency.time)
        setMonthDay(response.pimFrequency.days)
        setFirstImportEnable(response.pimFrequency.first_import)
        setSelectedDays(JSON.parse(response.pimFrequency.days))
        setSelectedAttributeIDs(response.pimFrequency.attribute_ids.split(",").map(Number))
        setShowPIMSecondStep(true)
        setShowPIMThirdStep(true)
        setCheckStartImportNow(response.pimFrequency.start_import)
        setImportChanged(response.pimFrequency.import_changed)
      })
      .catch((error) => {
        setShowPIMSecondStep(false)
        setShowPIMThirdStep(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PIMSaveMsg])

  useEffect(() => {
    PIMServicesInstance.pimProgressList(organizationID, pageNumber).then((response) => {
      setPIMProgressList(response.pimprogresses)
      setTotalProgressPages(response.totalPages)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  const handleSubmit = (event) => {
    event.preventDefault()
    PIMServicesInstance.savePIMConfig(organizationID, PIMFields)
      .then((response) => {
        if (response.accessToken.error === "invalid_client") {
          setPIMSaveMsg(t("PIM_CONFIG.AUTH_FAILED"))
        } else {
          setPIMSaveMsg(response.message)
          setShowPIMFirstStep(false)
          setShowPIMSecondStep(true)
        }
      })
      .catch(() => {
        setPIMSaveMsg(t("PIM_CONFIG.AUTH_FAILED"))
      })
  }
  const handleChange = (event) => {
    if (event.keyCode === "13") {
      handleSubmit(event)
    }
    setPIMFields({
      ...PIMFields,
      [event.target.name]: event.target.value,
    })
  }

  const savePIMFrequency = () => {
    var confirmed = window.confirm(t("CONFIRM_WINDOW.SAVE_ATTRIBUTES"))
    if (confirmed) {
      const params = {
        frequency: dailyWeeklyMonthly,
        days: dailyWeeklyMonthly === "weekly" ? JSON.stringify(selectedDays) : monthDay,
        time: selectedTime,
      }
      if (selectedAttributeIDs.length === 0) {
        setSaveFrequencyMsg(t("PIM_CONFIG.ATTRIBUTE_MANDATORY"))
        return
      }
      if (!dailyWeeklyMonthly) {
        setSaveFrequencyMsg(t("PIM_CONFIG.FREQUENCY_MANDATORY"))
        return
      }
      if (dailyWeeklyMonthly === "weekly" && !Object.values(selectedDays).some((value) => value)) {
        setSaveFrequencyMsg(t("PIM_CONFIG.DAY_MANDATORY"))
        return
      }
      if (dailyWeeklyMonthly === "monthly" && !monthDay) {
        setSaveFrequencyMsg(t("PIM_CONFIG.DAY_MANDATORY"))
        return
      }

      PIMServicesInstance.setPIMFrequency(
        organizationID,
        dailyWeeklyMonthly,
        params.days,
        params.time,
        selectedAttributeIDs.join(","),
        checkStartImportNow,
        importChanged
      ).then((response) => {
        setSaveFrequencyMsg(response.message)
        setShowFirstImportButton(true)
        setFirstImportEnable("started")
      })
    }
  }

  const handleAttributeSelect = (data) => {
    setSelectedAttributeIDs(data.value)
  }

  const handleExportAttributeSelectDesc = (data) => {
    setExportSelectedAttributeDescID(data.value)
  }
  const handleExportAttributeSelectMeta = (data) => {
    setExportSelectedAttributeMetaID(data.value)
  }
  const setAutoApprove = (autoManual) => {
    if (autoManual === "automatic") {
      var confirmed = window.confirm(t("CONFIRM_WINDOW.SET_AUTO_APPROVE"))
    } else {
    }
    if (confirmed || autoManual === "manual") {
      setMoveToFeed(autoManual)
      PIMServicesInstance.setAutoMunualApprove(organizationID, autoManual)
    }
  }

  const saveExportToField = () => {
    PIMServicesInstance.saveExportToField(organizationID, exportSelectedAttributeDescID, exportSelectedAttributeMetaID).then(
      (response) => {
        setExportFieldMsg(response.message)
      }
    )
  }

  const handlePageClick = (page) => {
    setPageNumber(page)
  }

  const handleNextClick = () => {
    if (pageNumber < totalProgressPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const handlePreviousClick = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const handleFirstClick = () => {
    setPageNumber(1)
  }

  const handleLastClick = () => {
    setPageNumber(totalProgressPages)
  }
  const generatePageNumbers = () => {
    const pageNumbers = []
    const maxPagesToShow = 7
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2)

    let startPage = Math.max(1, pageNumber - halfMaxPagesToShow)
    let endPage = Math.min(totalProgressPages, pageNumber + halfMaxPagesToShow)

    if (pageNumber - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalProgressPages, endPage + (halfMaxPagesToShow - (pageNumber - 1)))
    }

    if (pageNumber + halfMaxPagesToShow > totalProgressPages) {
      startPage = Math.max(1, startPage - (pageNumber + halfMaxPagesToShow - totalProgressPages))
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }

    return pageNumbers
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center d-lg-none d-block pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3  mb-1">
              <div className="col-md-3 col-lg-7  col-12 px-4 ">
                <h1 className="h1">{t("PIM_CONFIG.FEED")}</h1>
                <div className="breadcrumb">
                  <Link to={"/tools"}>{t("GENERAL.TOOLS")}</Link>
                  &nbsp;&raquo;&nbsp;
                  {t("PIM_CONFIG.FEED")}
                </div>
              </div>
              <div className="col-md-3 col-lg-3 p-4 ">&nbsp;</div>
              <TopBar />
            </div>
            <div className="container-fluid">
              <hr />
            </div>

            {/* STEP 1*/}
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <h4 className="h4">{t("PIM_CONFIG.STEP_ONE")}</h4>
                </div>
              </div>
              {showPIMFirstStep ? (
                <form action="post" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h6>{t("PIM_CONFIG.URL")}</h6>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="pim_url"
                        className="form-control"
                        placeholder=""
                        aria-label=""
                        aria-describedby=""
                        value={PIMFields.pim_url}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-lg-8  pt-4">
                      <h6>{t("PIM_CONFIG.USERNAME")}</h6>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="pim_username"
                        className="form-control"
                        placeholder=""
                        aria-label=""
                        aria-describedby=""
                        value={PIMFields.pim_username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-lg-8 pt-4">
                      <h6>{t("PIM_CONFIG.PASSWORD")}</h6>
                    </div>

                    <div className="col-12 col-lg-8">
                      <input
                        type="password"
                        name="pim_password"
                        className="form-control"
                        placeholder=""
                        aria-label=""
                        aria-describedby=""
                        value={PIMFields.pim_password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-lg-8  pt-4">
                      <h6>{t("PIM_CONFIG.STRUCTURE_ID")}</h6>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="pim_structure"
                        className="form-control"
                        placeholder=""
                        aria-label=""
                        aria-describedby=""
                        value={PIMFields.pim_structure}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-lg-8  pt-4">
                      <button className="btn btn-md fm-btn w-auto float-left me-md-2" type="submit">
                        {t("GENERAL.SAVE_AND_AUTHENTICATE")}
                      </button>
                      <button
                        className="btn btn-md fm-btn w-auto float-left me-md-2 m-2"
                        type="button"
                        onClick={() => {
                          setShowPIMFirstStep(false)
                          setShowPIMSecondStep(false)
                          setShowPIMThirdStep(true)
                        }}
                      >
                        {t("GENERAL.CANCEL")}
                      </button>
                      {PIMSaveMsg ? PIMSaveMsg : ""}
                    </div>
                  </div>
                </form>
              ) : (
                (showPIMSecondStep || showPIMThirdStep) && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        {t("PIM_CONFIG.FEED_CONNECTION")}: <b>{PIMFields.pim_structure}</b>
                        <br />
                        <br />
                        <button
                          onClick={() => {
                            setShowPIMFirstStep(true)
                            setShowPIMSecondStep(false)
                            setShowPIMThirdStep(false)
                          }}
                          className="btn btn-md fm-btn me-md-2"
                        >
                          {t("PIM_CONFIG.CHANGE")}
                        </button>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>

            {/* STEP 1*/}

            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12">
                  <hr />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12 navigateButton">
                  <div className="" align="right">
                    <ul className="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link  active"
                          id="pills-completed-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-completed"
                          type="button"
                          role="tab"
                          aria-controls="pills-completed"
                          aria-selected="false"
                        >
                          {t("PIM_CONFIG.IMPORT")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link  "
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          {t("PIM_CONFIG.EXPORT")}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 pt-4">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-completed"
                      role="tabpanel"
                      aria-labelledby="pills-completed-tab"
                    >
                      {/* STEP 2*/}
                      <div className="mt-4">
                        {showPIMSecondStep && showPIMThirdStep === false ? (
                          <>
                            <div className="row mt-4">
                              <div className="col-12 col-lg-8">
                                <h4 className="h4">{t("PIM_CONFIG.STEP_TWO")}</h4>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-lg-8">
                                <p>{t("PIM_CONFIG.AUTO_IMPORT_INSTRUC")}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-2"></div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-lg-4">
                                <div className="pt-3">
                                  {t("PIM_CONFIG.LAST_IMPORT")}:{" "}
                                  {PIMFields.last_cron_date === "0000" ? "Never" : PIMFields.last_cron_date}
                                </div>
                              </div>
                              <div className="col-1 pt-2">
                                {firstImportEnable === "started" ? (
                                  <>
                                    <button
                                      className="btn btn-md fm-btn me-md-2"
                                      onClick={() => ResumeStopFirstImport("stopped")}
                                      type="submit"
                                    >
                                      {t("GENERAL.STOP")}
                                    </button>
                                  </>
                                ) : firstImportEnable === "stopped" ? (
                                  <>
                                    <button
                                      className="btn btn-md fm-btn me-md-2"
                                      onClick={() => ResumeStopFirstImport("started")}
                                      type="submit"
                                    >
                                      {t("GENERAL.RESUME")}
                                    </button>
                                  </>
                                ) : (
                                  " "
                                )}
                              </div>
                              <div className="col-3"></div>
                            </div>

                            <div className="row">
                              <div className="mt-4">
                                <h6 className="h5">{t("PIM_CONFIG.SELECT_ATTRIBUTES")}</h6>
                              </div>
                            </div>

                            <div className="row pt-4">
                              <div className="col-12 col-lg-8">
                                <Dropdown
                                  value={selectedAttributeIDs}
                                  fluid
                                  search
                                  multiple
                                  clearable
                                  selection
                                  onChange={(data) => handleAttributeSelect(data)}
                                  options={attributesList}
                                />
                              </div>
                            </div>
                            <div className="row pt-4">
                              <div className="col-12 col-lg-8">
                                <button
                                  className="btn btn-md m-2 fm-btn me-md-2"
                                  onClick={() => {
                                    setShowPIMThirdStep(true)
                                    setShowPIMSecondStep(false)
                                  }}
                                >
                                  {t("PIM_CONFIG.CONTINUE_IMPORT")}
                                </button>
                                <button
                                  className="btn btn-md m-2 fm-btn me-md-2"
                                  type="button"
                                  onClick={() => {
                                    setShowPIMFirstStep(false)
                                    setShowPIMSecondStep(false)
                                    setShowPIMThirdStep(true)
                                  }}
                                >
                                  {t("GENERAL.CANCEL")}
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          showPIMThirdStep && (
                            <>
                              <div className="row mt-4">
                                <div className="col-12 col-lg-8">
                                  <h4 className="h4">{t("PIM_CONFIG.STEP_TWO")}</h4>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-12">
                                  <button
                                    onClick={() => {
                                      setShowPIMSecondStep(true)
                                      setShowPIMThirdStep(false)
                                    }}
                                    className="btn btn-md fm-btn me-md-2"
                                  >
                                    {t("PIM_CONFIG.CHANGE")}
                                  </button>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>
                      {/* STEP 2*/}

                      {/* STEP 3*/}
                      <div className=" mt-4">
                        {showPIMThirdStep && (
                          <>
                            <div className="row">
                              <div className="col-12">
                                <hr />
                              </div>
                            </div>
                            <div className="row">
                              <div className=" col-12 col-lg-8 mt-4">
                                <h4 className="h4">{t("PIM_CONFIG.STEP_THREE")}</h4>
                                <p>{t("PIM_CONFIG.IMPORT_SCHEDULER_SUBLINE")}</p>
                              </div>
                            </div>

                            <div className="row pt-4">
                              <div className="col-12 col-lg-8">
                                <input
                                  type="checkbox"
                                  value="yes"
                                  checked={checkStartImportNow === true && "checked"}
                                  name="startimportnow"
                                  onChange={() => {
                                    setCheckStartImportNow(!checkStartImportNow)
                                  }}
                                  className="checkbox"
                                />{" "}
                                {t("PIM_CONFIG.START_IMPORT")}
                              </div>
                            </div>

                            <div className="row pt-4">
                              <div className="col-12 col-lg-8">
                                <input
                                  type="checkbox"
                                  value="yes"
                                  checked={importChanged === "yes" && "checked"}
                                  onChange={(e) => {
                                    setImportChanged(e.target.checked ? "yes" : "no")
                                  }}
                                  name="importtime"
                                  className="checkbox"
                                />{" "}
                                {t("PIM_CONFIG.CONTINUOUS_CHANGED")}
                              </div>
                            </div>
                            {importChanged === "yes" && (
                              <div className="row pt-4">
                                <div className="col-12  m-lg-3 col-lg-2">
                                  <br />
                                  <select className="form-control" onChange={handleSelectChange}>
                                    <option value="">{t("GENERAL.SELECT")}</option>
                                    <option value="hourly" selected={dailyWeeklyMonthly === "hourly" && "selected"}>
                                      {t("SCHEDULING.HOURLY")}
                                    </option>
                                    <option value="daily" selected={dailyWeeklyMonthly === "daily" && "selected"}>
                                      {t("SCHEDULING.DAILY")}
                                    </option>
                                    <option value="weekly" selected={dailyWeeklyMonthly === "weekly" && "selected"}>
                                      {t("SCHEDULING.WEEKLY")}
                                    </option>
                                    <option value="monthly" selected={dailyWeeklyMonthly === "monthly" && "selected"}>
                                      {t("SCHEDULING.MONTHLY")}
                                    </option>
                                  </select>
                                </div>
                                <div className="col-12 m-lg-3 mt-0 col-lg-5">
                                  {dailyWeeklyMonthly === "daily" ? (
                                    <div className="row">
                                      <div className="col-12 col-lg-4">
                                        {t("SCHEDULING.TIME")}
                                        <select className="form-control" onChange={handleTimeChange}>
                                          {times.map((time, index) => (
                                            <option
                                              selected={selectedTime === time + ":00" && "selected"}
                                              key={index}
                                              value={time}
                                            >
                                              {time}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  ) : dailyWeeklyMonthly === "weekly" ? (
                                    <div className="row">
                                      <div className="float-left w-auto px-3">
                                        <div className="row">{t("SCHEDULING.WEEKDAY")}</div>
                                        <div className="row">
                                          <div
                                            className={`col-1 p-0 weeklydays ml-1 ${
                                              selectedDays.monday && "selectedDay"
                                            }`}
                                            onClick={() =>
                                              selectWeeklyDays({
                                                monday: !selectedDays.monday,
                                              })
                                            }
                                          >
                                            {t("SCHEDULING.MON")}
                                          </div>
                                          <div
                                            className={`col-1 p-0 weeklydays ml-1 ${
                                              selectedDays.tuesday && "selectedDay"
                                            }`}
                                            onClick={() =>
                                              selectWeeklyDays({
                                                tuesday: !selectedDays.tuesday,
                                              })
                                            }
                                          >
                                            {t("SCHEDULING.TUE")}
                                          </div>
                                          <div
                                            className={`col-1 p-0 weeklydays ml-1 ${
                                              selectedDays.wednesday && "selectedDay"
                                            }`}
                                            onClick={() =>
                                              selectWeeklyDays({
                                                wednesday: !selectedDays.wednesday,
                                              })
                                            }
                                          >
                                            {t("SCHEDULING.WED")}
                                          </div>
                                          <div
                                            className={`col-1 p-0 weeklydays ml-1 ${
                                              selectedDays.thursday && "selectedDay"
                                            }`}
                                            onClick={() =>
                                              selectWeeklyDays({
                                                thursday: !selectedDays.thursday,
                                              })
                                            }
                                          >
                                            {t("SCHEDULING.THU")}
                                          </div>
                                          <div
                                            className={`col-1 p-0 weeklydays ml-1 ${
                                              selectedDays.friday && "selectedDay"
                                            }`}
                                            onClick={() =>
                                              selectWeeklyDays({
                                                friday: !selectedDays.friday,
                                              })
                                            }
                                          >
                                            {t("SCHEDULING.FRI")}
                                          </div>
                                          <div
                                            className={`col-1 p-0 weeklydays ml-1 ${
                                              selectedDays.saturday && "selectedDay"
                                            }`}
                                            onClick={() =>
                                              selectWeeklyDays({
                                                saturday: !selectedDays.saturday,
                                              })
                                            }
                                          >
                                            {t("SCHEDULING.SAT")}
                                          </div>
                                          <div
                                            className={`col-1 p-0 weeklydays ml-1 ${
                                              selectedDays.sunday && "selectedDay"
                                            }`}
                                            onClick={() =>
                                              selectWeeklyDays({
                                                sunday: !selectedDays.sunday,
                                              })
                                            }
                                          >
                                            {t("SCHEDULING.SUN")}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="float-left w-25">
                                        <div className="row">{t("SCHEDULING.TIME")}</div>
                                        <div className="row">
                                          <div className="col-12 p-0">
                                            <select className="form-control" onChange={handleTimeChange}>
                                              {times.map((time, index) => (
                                                <option
                                                  selected={selectedTime === time + ":00" && "selected"}
                                                  key={index}
                                                  value={time}
                                                >
                                                  {time}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    dailyWeeklyMonthly === "monthly" && (
                                      <div className="row">
                                        <div className="col-6 col-lg-3">
                                          {t("SCHEDULING.DATE")}
                                          <select className="form-control" onChange={handleMonthDayChange}>
                                            <option value="">{t("GENERAL.SELECT")}</option>
                                            {days.map((day) => (
                                              <option key={day} value={day} selected={monthDay === day && "selected"}>
                                                {day}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="col-6 col-lg-3">
                                          {t("SCHEDULING.TIME")}
                                          <select className="form-control" onChange={handleTimeChange}>
                                            {times.map((time, index) => (
                                              <option
                                                selected={selectedTime === time + ":00" && "selected"}
                                                key={index}
                                                value={time}
                                              >
                                                {time}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="row pt-4">
                              <div className="col-12">
                                <button
                                  disabled={firstImportEnable === "started" && "disabled"}
                                  className="btn btn-md fm-btn me-md-2"
                                  type="button"
                                  onClick={() => savePIMFrequency()}
                                >
                                  {t("GENERAL.SAVE")}
                                </button>
                                {saveFrequencyMsg && saveFrequencyMsg}
                              </div>
                            </div>
                            <div className="row pt-4">
                              <div className="col-12"></div>
                            </div>
                          </>
                        )}
                        <div className="row">
                          <div className=" col-8 ">
                            <h4 className="h4">{t("PIM_CONFIG.IMPORT_HISTORY")}</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-lg-8 fm-content-box p-4">
                            <div className="col-12 table-responsive fm-table">
                              <table className="table table-hover align-top">
                                <tbody>
                                  <tr>
                                    <th scope="col">{t("PIM_CONFIG.PROGRESS")}</th>
                                    <th scope="col">{t("PIM_CONFIG.LAST_RUN")}</th>
                                    <th scope="col">{t("PIM_CONFIG.NEXT_RUN")}</th>
                                  </tr>
                                  {PIMProgressList &&
                                    PIMProgressList.map((val) => {
                                      return (
                                        <tr>
                                          <td>
                                            <div className="pt-3">
                                              <div className="creditProgressBarContainer">
                                                <div
                                                  className="creditProgressBarGreen"
                                                  style={{
                                                    width: Math.round((val.imported * 100) / val.total_products) + "%",
                                                  }}
                                                >
                                                  &nbsp;
                                                </div>
                                              </div>
                                              <div className="creditProgressText">
                                                <b>{t("PIM_CONFIG.IMPORTED")}:</b> {val.imported}{" "}
                                                {t("CREDIT_PROGRESS.OUT_OF")} {val.total_products}.{" "}
                                                <b>{t("CREDIT_PROGRESS.REMAINING")}:</b>{" "}
                                                {val.total_products - val.imported}
                                              </div>
                                            </div>
                                          </td>
                                          <td>{val.cron_start_date}</td>
                                          <td>{val.next_cron_date}</td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                                <tr>
                                  <td colspan="5">
                                    <button className="paging" onClick={handleFirstClick} disabled={pageNumber === 1}>
                                      {t("GENERAL.FIRST")}
                                    </button>
                                    <button
                                      className="paging"
                                      onClick={handlePreviousClick}
                                      disabled={pageNumber === 1}
                                    >
                                      {t("GENERAL.PREVIOUS")}
                                    </button>
                                    {generatePageNumbers().map((page) => (
                                      <button
                                        className={pageNumber === page ? "currpaging" : "paging"}
                                        key={page}
                                        onClick={() => handlePageClick(page)}
                                      >
                                        {page}
                                      </button>
                                    ))}
                                    <button
                                      className="paging"
                                      onClick={handleNextClick}
                                      disabled={pageNumber === totalProgressPages}
                                    >
                                      {t("GENERAL.NEXT")}
                                    </button>
                                    <button
                                      className="paging"
                                      onClick={handleLastClick}
                                      disabled={pageNumber === totalProgressPages}
                                    >
                                      {t("GENERAL.LAST")}
                                    </button>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div>
                          <br />
                        </div>
                      </div>
                      {/* STEP 3*/}
                    </div>
                    <div class="tab-pane fade " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                      <div className="row m-0 pb-2">
                        <div className="col-12 col-lg-2  pt-2">
                          <div className="row">
                            <div className="float-left w-auto pl-0 ">
                              <input
                                onClick={() => setAutoApprove("manual")}
                                checked={moveToFeed === "manual" && "checked"}
                                name="feed"
                                className="checkbox"
                                type="radio"
                              />
                            </div>
                            <div className="float-left w-auto px-0">
                              <div className="h6">{t("PIM_CONFIG.MANUAL_APPROVE")}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-2 pt-2">
                          <div className="row">
                            <div className="float-left w-auto pl-0 ">
                              <input
                                onClick={() => setAutoApprove("automatic")}
                                checked={moveToFeed === "automatic" && "checked"}
                                name="feed"
                                className="checkbox"
                                type="radio"
                              />
                            </div>
                            <div className="float-left w-auto px-0">
                              <div className="h6">{t("PIM_CONFIG.AUTO_APPROVE")}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 pb-2 pt-3">
                        <div className="col-12">
                          <h6>{t("PIM_CONFIG.SELECT_PRODUCT_ATTRIBUTE")}</h6>
                        </div>
                        <div className="col-12 col-lg-4">
                          <Dropdown
                            value={exportSelectedAttributeDescID}
                            fluid
                            search
                            clearable
                            selection
                            placeholder={t("GENERAL.SELECT")}
                            onChange={(data) => handleExportAttributeSelectDesc(data)}
                            options={attributesList}
                          />
                        </div>
                      </div>
                      <div className="row m-0 pb-2 pt-3">
                        <div className="col-12">
                          <h6>{t("PIM_CONFIG.SELECT_META_ATTRIBUTE")}</h6>
                        </div>
                        <div className="col-12 col-lg-4">
                          <Dropdown
                            value={exportSelectedAttributeMetaID}
                            fluid
                            search
                            clearable
                            selection
                            placeholder={t("GENERAL.SELECT")}
                            onChange={(data) => handleExportAttributeSelectMeta(data)}
                            options={attributesList}
                          />
                        </div>
                      </div>
                      <div className="row  m-0 pb-2">
                        <div className="col-2  pt-2">
                          <button onClick={() => saveExportToField()} className="btn btn-md fm-btn me-md-2">
                            {t("GENERAL.SAVE")}
                          </button>
                          {exportFieldMsg && exportFieldMsg}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default PIMConfig
