import httpClient from "../utils/httpClientSetup"

class AuthService {
  login(email, password) {
    const data = {
      email: email,
      password: password,
    }

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data,
      url: process.env.REACT_APP_API_SERVER_URL + "login",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }
}
const authServiceInstance = new AuthService()
export default authServiceInstance
