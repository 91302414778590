import React, { useEffect, useState } from "react"
import AssistantService from "../services/AssistantService"

function AttributeNames({ idlist }) {
  const [attributeNames, setAttributeNames] = useState([])

  useEffect(() => {
    const ids = idlist.split(",")

    const fetchAttributeNames = async () => {
      try {
        const names = await Promise.all(
          ids.map(async (id) => {
            if (id.endsWith("0000000000")) {
              const response = await AssistantService.getAttributeGroupNameById(parseInt(id.slice(0, -10), 10))
              return "All " + response.data.attribute.attribute_value
            } else {
              const response = await AssistantService.getAttributeNamesByID(parseInt(id, 10))
              return response.data.attribute.name
            }
          })
        )
        setAttributeNames(names)
      } catch (error) {}
    }

    fetchAttributeNames()
  }, [idlist])

  return (
    <>
      <div>
        {attributeNames.length > 0 ? (
          attributeNames.map((name, index) => (
            <span key={index}>
              {name}
              {index !== attributeNames.length - 1 && ", "}
            </span>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  )
}

export default AttributeNames
