import React, { useEffect, useState } from "react"
import ProductService from "../services/ProductService"
import AssistantService from "../services/AssistantService"
import CreditBar from "./CreditBar"
import TopBar from "./Topbar"
import ThumbUpBlank from "../images/thumb-up-blank.png"
import ThumbUp from "../images/thumb-up.png"
import InfoIcon from "../images/info.png"
import JobDesc from "../images/job-description.png"
import CloseIcon from "../images/close.png"
import { useTranslation } from "react-i18next"
import { useParsedAssistantIdFromParams } from "../utils/utils"

function CompletedTask() {
  const { assistantID } = useParsedAssistantIdFromParams()
  const { t } = useTranslation()
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [loadingMsg, setLoadingMsg] = useState(false)
  const [totalProducts, setTotalProducts] = useState("")
  const [productList, setProductList] = useState("")
  const [MTFtotalProducts, setMTFTotalProducts] = useState("")
  const [MTFproductList, setMTFProductList] = useState("")
  const [RTFtotalProducts, setRTFTotalProducts] = useState("")
  const [RTFproductList, setRTFProductList] = useState("")
  const [InCompletetotalProducts, setInCompleteTotalProducts] = useState("")
  const [InCompleteproductList, setInCompleteProductList] = useState("")
  const [additionalData, setAdditionalData] = useState({})
  const [toggleState, setToggleState] = useState({})
  const [selectedAsExample, setSelectedAsExample] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [searchISBN, setSearchISBN] = useState("")
  const [showDescModal, setShowDescModal] = useState(false)
  const [jobDescription, setJobDescription] = useState([])
  const [productDescription, setProductDescription] = useState()
  const [manualApproveOutputID, setManualApproveOutputID] = useState({})
  const [manualApproveOutputIDSaved, setManualApproveOutputIDSaved] = useState({})
  const [totalCredit, setTotalCredit] = useState()
  const [creditUsed, setCreditUsed] = useState()
  const [showsaveManualOutputMsg, setShowsaveManualOutputMsg] = useState()
  const handleSearchChange = (event) => {
    setSearchISBN(event.target.value)
  }
  const [pagination, setPagination] = useState({
    1: { currentPage: 1, totalPages: 1 },
    2: { currentPage: 1, totalPages: 1 },
    3: { currentPage: 1, totalPages: 1 },
    4: { currentPage: 1, totalPages: 1 },
  })
  useEffect(() => {
    ProductService.productList(assistantID, "completed", "", pagination["1"].currentPage, organizationID)
      .then((response) => {
        setTotalProducts(response.data.total_products)
        setProductList(response.data.products)
        if (pagination["1"]?.totalPages !== response.data.total_pages) {
          updatePaginationForList("1", response.data.total_pages)
        }
        response.data.products.forEach((book) => {
          fetchAdditionalData(assistantID, book.product_isbn, "completed")
        })
        setLoadingMsg(false)
      })
      .catch(() => {
        setLoadingMsg(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID, pagination["1"], assistantID])

  useEffect(() => {
    ProductService.productList(assistantID, "readytofeed", "", pagination["3"].currentPage, organizationID)
      .then((response) => {
        setRTFTotalProducts(response.data.total_products)
        setRTFProductList(response.data.products)
        if (pagination["3"]?.totalPages !== response.data.total_pages) {
          updatePaginationForList("3", response.data.total_pages)
        }
        setLoadingMsg(false)
      })
      .catch(() => {
        setLoadingMsg(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID, pagination["3"], assistantID])
  useEffect(() => {
    ProductService.productList(assistantID, "movedtofeed", "", pagination["4"].currentPage, organizationID)
      .then((response) => {
        setMTFTotalProducts(response.data.total_products)
        setMTFProductList(response.data.products)
        if (pagination["4"]?.totalPages !== response.data.total_pages) {
          updatePaginationForList("4", response.data.total_pages)
        }
        response.data.products.forEach((book) => {
          fetchAdditionalData(assistantID, book.product_isbn, "movedtofeed")
        })
        setLoadingMsg(false)
      })
      .catch(() => {
        setLoadingMsg(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID, pagination["4"], assistantID])

  useEffect(() => {
    setLoadingMsg(true)

    ProductService.exampleList(assistantID, organizationID).then((response) => {
      if (!response.data) {
        return []
      }
      const newOutputIds = response.data.map((item) => item.id)
      setSelectedAsExample((prevData) => {
        const newData = { ...prevData }
        newOutputIds.forEach((outputId) => {
          newData[outputId] = outputId
        })
        return newData
      })
      ProductService.outputIDSavedForPush(assistantID, organizationID).then((response) => {
        setManualApproveOutputIDSaved(response.output_ids)
      })
      ProductService.productList(assistantID, "incomplete", "", pagination["2"].currentPage, organizationID)
        .then((response) => {
          setInCompleteTotalProducts(response.data.total_products - response.data.total_generated)
          setInCompleteProductList(response.data.products)
          if (pagination["2"]?.totalPages !== response.data.total_pages) {
            updatePaginationForList("2", response.data.total_pages)
          }
          setLoadingMsg(false)
        })
        .catch(() => {
          setLoadingMsg(false)
        })
    })
    AssistantService.getConsumedCredits(assistantID, organizationID).then((assistresponse) => {
      setTotalCredit(assistresponse.credits)
      setCreditUsed(assistresponse.consumed)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID, pagination["2"], assistantID])

  const changeOutputExampleStatus = async (shouldUseAsExample, outputID) => {
    var confirmed
    if (shouldUseAsExample) {
      confirmed = window.confirm(t("CONFIRM_WINDOW.ADD_TRAINING_DATA"))
    } else {
      confirmed = window.confirm(t("CONFIRM_WINDOW.REMOVE_TRAINING_DATA"))
    }
    if (confirmed) {
      await ProductService.changeOutputExampleStatus(outputID).then(() => {
        setSelectedAsExample((prevData) => {
          if (shouldUseAsExample === true) {
            return { ...prevData, [outputID]: outputID }
          } else {
            const { [outputID]: _, ...newData } = prevData
            return newData
          }
        })
      })
    }
  }
  const productSearch = () => {
    ProductService.productList(assistantID, "completedsearch", searchISBN, 1).then((response) => {
      setProductList(response.data.products)
    })
  }
  const fetchAdditionalData = async (assistantID, isbn, type) => {
    try {
      ProductService.outputList(assistantID, isbn, type, organizationID).then((response) => {
        setAdditionalData((prevData) => ({
          ...prevData,
          [isbn]: response.data,
        }))
      })
    } catch (error) {}
  }
  const showOutputs = (isbn) => {
    setToggleState((prevState) => ({
      ...prevState,
      [isbn]: !prevState[isbn],
    }))
  }
  const viewProductDescription = (prodDesc) => {
    setProductDescription(prodDesc)
    setShowDescModal(true)
  }
  const viewJobDescription = (outputid) => {
    const promptData = JSON.parse(outputid)
    setJobDescription(promptData)
    handleOpenModal()
  }
  const handleCloseProdDesc = () => {
    setShowDescModal(false)
  }
  const handleClose = () => {
    setShowModal(false)
  }
  const handleOpenModal = () => {
    setShowModal(true)
  }
  const updatePaginationForList = (listId, totalPages) => {
    setPagination((prev) => ({
      ...prev,
      [listId]: {
        ...prev[listId],
        totalPages: totalPages,
      },
    }))
  }

  const setPageForList = (listId, page) => {
    setPagination((prev) => ({
      ...prev,
      [listId]: {
        ...prev[listId],
        currentPage: page,
      },
    }))
  }
  const handleNextClick = (listId) => {
    if (pagination[listId].currentPage < pagination[listId].totalPages) {
      setPageForList(listId, pagination[listId].currentPage + 1)
    }
  }
  const handlePreviousClick = (listId) => {
    if (pagination[listId].currentPage > 1) {
      setPageForList(listId, pagination[listId].currentPage - 1)
    }
  }
  const handleFirstClick = (listId) => {
    setPageForList(listId, 1)
  }
  const handleLastClick = (listId) => {
    setPageForList(listId, pagination[listId].totalPages)
  }
  const generatePageNumbers = (listId) => {
    const pageNumbers = []
    const maxPagesToShow = 7
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2)
    const currentPage = pagination[listId].currentPage
    const totalPages = pagination[listId].totalPages
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow)
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow)
    if (currentPage - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalPages, endPage + (halfMaxPagesToShow - (currentPage - 1)))
    }
    if (currentPage + halfMaxPagesToShow > totalPages) {
      startPage = Math.max(1, startPage - (currentPage + halfMaxPagesToShow - totalPages))
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }
    return pageNumbers
  }
  const handleCheckboxChange = (outputID, isChecked) => {
    setManualApproveOutputID((prevData) => {
      if (isChecked) {
        return { ...prevData, [outputID]: outputID }
      } else {
        const { [outputID]: _, ...rest } = prevData
        return rest
      }
    })
  }
  const saveManualOutputID = () => {
    ProductService.saveManualOutputID(assistantID, organizationID, manualApproveOutputID).then(() => {
      setShowsaveManualOutputMsg("Output scheduled for feed")
    })
  }
  const nl2br = (text) => {
    return text.split("\n").map((str, index) => (
      <>
        {str}
        <br />
      </>
    ))
  }
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">{t("TASKS.COMPLETED.COMPLETED_TASKS")}</h1>
                <div className="breadcrumb">{t("TASKS.COMPLETED.COMPLETED_TASKS")}</div>
              </div>
              <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
              <TopBar />
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
              <div className="col-md-3 col-lg-8 px-4 ">
                <p>{t("TASKS.COMPLETED.PAGE_SUBLINE")}</p>
              </div>
            </div>
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-6"></div>
              </div>
            </div>
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12 col-lg-9 navigateButton">
                  <div className="" align="right">
                    <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link  active"
                          id="pills-completed-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-completed"
                          type="button"
                          role="tab"
                          aria-controls="pills-completed"
                          aria-selected="false"
                        >
                          {t("TASKS.COMPLETED.OPTIMIZED")} ({totalProducts})
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link  "
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          {t("TASKS.COMPLETED.NOT_OPTIMIZED")} ({InCompletetotalProducts})
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link "
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          {t("TASKS.READY_FOR_EXPORT")} ({RTFtotalProducts})
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link "
                          id="pills-service-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-service"
                          type="button"
                          role="tab"
                          aria-controls="pills-service"
                          aria-selected="false"
                        >
                          {t("TASKS.EXPORTED")} ({MTFtotalProducts})
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      onChange={handleSearchChange}
                      className="form-control"
                      placeholder={t("GENERAL.SEARCH")}
                      aria-label=""
                      aria-describedby=""
                    />
                    <button onClick={() => productSearch()} className="btn fm-btn" type="button">
                      {t("GENERAL.SEARCH")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 fm-content-box pt-3 px-1">
                  <div className="col-12 table-responsive mt-4 fm-table">
                    <div class="tab-content" id="pills-tabContent">
                      {/* COMPLETED TAB */}
                      <div
                        class="tab-pane fade show active"
                        id="pills-completed"
                        role="tabpanel"
                        aria-labelledby="pills-completed-tab"
                      >
                        <div className="container-fluid pt-2 px-md-2 px-1">
                          <div className="">
                            <div className="float-start training-page-col-1 table-col-heading">&nbsp;</div>
                            <div className="float-start training-page-col-2 table-col-heading">SR.</div>
                            <div className="float-start training-page-col-3 table-col-heading">{t("TASKS.ISBN")}</div>
                            <div className="float-start training-page-col-4 table-col-heading">{t("TASKS.TITLE")}</div>
                            <div className="float-start training-page-col-5 table-col-heading">
                              {t("TASKS.SEO_OPTIMIZED_TEXT")}
                            </div>
                            <div className="float-end text-end training-page-col-6 table-col-heading">
                              {t("GENERAL.ACTION")}
                            </div>
                          </div>
                          <div className="">
                            <div className="float-start w-100">
                              {loadingMsg && <div className="text-center w-100">{t("GENERAL.LOADING_DATA")}</div>}
                              {productList &&
                                productList.map((val) => {
                                  return (
                                    <>
                                      <div
                                        className="float-start pt-2 pb-2 w-100"
                                        style={{
                                          borderTop: "1px solid #0402020f",
                                        }}
                                      >
                                        <div className="float-start float-start training-page-col-1">
                                          {additionalData[val.product_isbn] != null ? (
                                            <>
                                              <div
                                                className="plus-minus"
                                                onClick={() => showOutputs(val.product_isbn)}
                                                width="12"
                                              >
                                                {toggleState[val.product_isbn] ? "-" : "+"}{" "}
                                              </div>
                                            </>
                                          ) : (
                                            <div>&nbsp;</div>
                                          )}
                                        </div>
                                        <div className="float-start training-page-col-2">{val.serial_number}</div>
                                        <div className="float-start training-page-col-3">{val.product_isbn}</div>
                                        <div className="float-start training-page-col-4">{val.product_name}</div>
                                        <div className="float-start training-page-col-5">
                                          {nl2br(val.output)}
                                          <div style={{ clear: "both" }}></div>
                                          <div
                                            id={val.product_isbn}
                                            style={{
                                              display: toggleState[val.product_isbn] ? "block" : "none",
                                            }}
                                          >
                                            {additionalData[val.product_isbn] != null && (
                                              <>
                                                <br />
                                                <div>
                                                  <b>{t("TASKS.OUTPUT_HISTORY")}</b>
                                                </div>
                                                {additionalData[val.product_isbn] &&
                                                  additionalData[val.product_isbn].map((outputData) => {
                                                    return (
                                                      <>
                                                        <div className="outputListItem" id={"outputitem_" + outputData.id}>
                                                          <div className="outputListItemOutput">
                                                            {nl2br(outputData.output)}
                                                          </div>
                                                          <div className="outputListItemAction">
                                                            <i
                                                              className="bi bi-card-list topbar-icons"
                                                              onClick={() =>
                                                                viewJobDescription(outputData.prompt, outputData.prompt2)
                                                              }
                                                              alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                                              title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                                            ></i>
                                                            &nbsp;.&nbsp;
                                                            {selectedAsExample[outputData.id] ? (
                                                              <i
                                                                class="bi bi-hand-thumbs-up-fill topbar-icons  thumbs-icon"
                                                                onClick={() => changeOutputExampleStatus(false, outputData.id)}
                                                                src={ThumbUp}
                                                                title={t("TASKS.REMOVE_EXAMPLE")}
                                                              ></i>
                                                            ) : (
                                                              <i
                                                                class="bi bi-hand-thumbs-up topbar-icons  thumbs-icon"
                                                                onClick={() => changeOutputExampleStatus(true, outputData.id)}
                                                                src={ThumbUp}
                                                                title={t("TASKS.SET_EXAMPLE")}
                                                              ></i>
                                                            )}
                                                          </div>
                                                          <div className="outputListItemAction">&nbsp;</div>
                                                        </div>
                                                      </>
                                                    )
                                                  })}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                        <div className="float-end text-end training-page-col-6">
                                          <i
                                            className="bi bi-info-circle topbar-icons"
                                            src={InfoIcon}
                                            onClick={() => viewProductDescription(val.product_description)}
                                            title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                          ></i>
                                          &nbsp;.&nbsp;
                                          <i
                                            className="bi bi-card-list topbar-icons"
                                            src={JobDesc}
                                            onClick={() => viewJobDescription(val.prompt, val.prompt2)}
                                            title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                          ></i>
                                          &nbsp;.&nbsp;
                                          {selectedAsExample[val.id] ? (
                                            <i
                                              class="bi bi-hand-thumbs-up-fill topbar-icons  thumbs-icon"
                                              onClick={() => changeOutputExampleStatus(false, val.id)}
                                              src={ThumbUp}
                                              title={t("TASKS.REMOVE_EXAMPLE")}
                                            ></i>
                                          ) : (
                                            <i
                                              class="bi bi-hand-thumbs-up topbar-icons thumbs-icon"
                                              onClick={() => changeOutputExampleStatus(true, val.id)}
                                              src={ThumbUpBlank}
                                              alt={t("TASKS.SET_EXAMPLE")}
                                              title={t("TASKS.SET_EXAMPLE")}
                                            ></i>
                                          )}
                                        </div>
                                      </div>
                                      <div className="float-end d-block d-sm-none text-end pb-2">
                                        <i
                                          className="bi bi-journals topbar-icons"
                                          src={InfoIcon}
                                          onClick={() => viewProductDescription(nl2br(val.output))}
                                          title={t("TASKS.VIEW_SEO_TEXT")}
                                        ></i>
                                        &nbsp;.&nbsp;
                                        <i
                                          className="bi bi-info-circle topbar-icons"
                                          src={InfoIcon}
                                          onClick={() => viewProductDescription(val.product_description)}
                                          title={t("TASKS.VIEW_ORIGINAL_TEXT")}
                                        ></i>
                                        &nbsp;.&nbsp;
                                        <i
                                          className="bi bi-card-list topbar-icons"
                                          src={JobDesc}
                                          onClick={() => viewJobDescription(val.prompt, val.prompt2)}
                                          title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                        ></i>
                                        &nbsp;.&nbsp;
                                        {selectedAsExample[val.id] ? (
                                          <i
                                            class="bi bi-hand-thumbs-up-fill topbar-icons  thumbs-icon"
                                            onClick={() => changeOutputExampleStatus(false, val.id)}
                                            src={ThumbUp}
                                            title={t("TASKS.REMOVE_EXAMPLE")}
                                          ></i>
                                        ) : (
                                          <i
                                            class="bi bi-hand-thumbs-up topbar-icons thumbs-icon"
                                            onClick={() => changeOutputExampleStatus(true, val.id)}
                                            src={ThumbUpBlank}
                                            alt={t("TASKS.SET_EXAMPLE")}
                                            title={t("TASKS.SET_EXAMPLE")}
                                          ></i>
                                        )}
                                      </div>
                                      <div
                                        className="d-sm-none"
                                        id={val.product_isbn}
                                        style={{
                                          display: toggleState[val.product_isbn] ? "block" : "none",
                                        }}
                                      >
                                        {additionalData[val.product_isbn] != null && (
                                          <>
                                            <div class="clearfix"></div>
                                            <div>
                                              <b>{t("TASKS.OUTPUT_HISTORY")}</b>
                                            </div>
                                            {additionalData[val.product_isbn] &&
                                              additionalData[val.product_isbn].map((value) => {
                                                return (
                                                  <>
                                                    <div className="outputListItem" id={"outputitem_" + value.id}>
                                                      <div className="outputListItemOutput">{nl2br(value.output)}</div>
                                                      <div className="outputListItemAction">
                                                        <i
                                                          className="bi bi-card-list topbar-icons"
                                                          onClick={() =>
                                                            viewJobDescription(value.prompt, value.prompt2)
                                                          }
                                                          alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                                          title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                                        ></i>
                                                        &nbsp;.&nbsp;
                                                        {selectedAsExample[value.id] ? (
                                                          <i
                                                            class="bi bi-hand-thumbs-up-fill topbar-icons  thumbs-icon"
                                                            onClick={() => changeOutputExampleStatus(false, value.id)}
                                                            src={ThumbUp}
                                                            title={t("TASKS.REMOVE_EXAMPLE")}
                                                          ></i>
                                                        ) : (
                                                          <i
                                                            class="bi bi-hand-thumbs-up topbar-icons  thumbs-icon"
                                                            onClick={() => changeOutputExampleStatus(true, value.id)}
                                                            src={ThumbUp}
                                                            title={t("TASKS.SET_EXAMPLE")}
                                                          ></i>
                                                        )}
                                                      </div>
                                                      <div className="outputListItemAction">&nbsp;</div>
                                                    </div>
                                                  </>
                                                )
                                              })}
                                          </>
                                        )}
                                      </div>
                                      <div class="clearfix"></div>
                                    </>
                                  )
                                })}
                            </div>
                          </div>
                          <div className="clearfix float-start col-12 paging-container">
                            <button className="paging" onClick={() => handleFirstClick("1")}>
                              {t("GENERAL.FIRST")}
                            </button>
                            <button className="paging" onClick={() => handlePreviousClick("1")}>
                              {t("GENERAL.PREVIOUS")}
                            </button>
                            {generatePageNumbers("1").map((page) => (
                              <button
                                className={pagination["1"].currentPage === page ? "currpaging" : "paging"}
                                key={page}
                                onClick={() => setPageForList("1", page)}
                              >
                                {page}
                              </button>
                            ))}
                            <button className="paging" onClick={() => handleNextClick("1")}>
                              {t("GENERAL.NEXT")}
                            </button>
                            <button className="paging" onClick={() => handleLastClick("1")}>
                              {t("GENERAL.LAST")}
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* COMPLETED TAB */}

                      {/* INCOMPLETED TAB */}
                      <div class="tab-pane fade " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="container-fluid pt-2 px-md-2 px-1">
                          <div className="">
                            <div className="float-start training-page-col-2 table-col-heading">SR.</div>
                            <div className="float-start training-page-col-3 table-col-heading">{t("TASKS.ISBN")}</div>
                            <div className="float-start training-page-col-4 table-col-heading">{t("TASKS.TITLE")}</div>
                            <div className="float-start training-page-col-5 table-col-heading">
                              {t("JOBS.ORIGINAL_TEXT")}
                            </div>
                          </div>
                          <div className="">
                            <div className="float-start">
                              {loadingMsg && <div className="text-center w-100">{t("GENERAL.LOADING_DATA")}</div>}
                              {InCompleteproductList &&
                                InCompleteproductList.map((val) => {
                                  return (
                                    <>
                                      <div
                                        className="float-start pt-2 pb-2 w-100"
                                        style={{
                                          borderTop: "1px solid #0402020f",
                                        }}
                                      >
                                        <div className="float-start training-page-col-2">{val.serial_number}</div>
                                        <div className="float-start training-page-col-3">{val.product_isbn}</div>
                                        <div className="float-start training-page-col-4">{val.product_name}</div>
                                        <div className="float-start training-page-col-5">
                                          {val.product_description
                                            .replace(/<P>/gi, "")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/<!--.*?-->|<\/?p>/g, "")
                                            .trim()}
                                        </div>
                                      </div>
                                      <div className="float-end d-block d-sm-none text-end pb-2">
                                        <i
                                          className="bi bi-info-circle topbar-icons"
                                          src={InfoIcon}
                                          onClick={() =>
                                            viewProductDescription(
                                              val.product_description
                                                .replace(/<P>/gi, "")
                                                .replace(/<[^>]*>/g, "")
                                                .replace(/<!--.*?-->|<\/?p>/g, "")
                                                .trim()
                                            )
                                          }
                                          title={t("TASKS.VIEW_ORIGINAL_TEXT")}
                                        ></i>
                                      </div>
                                      <div class="clearfix"></div>
                                    </>
                                  )
                                })}
                            </div>
                          </div>
                          <div className="clearfix pt-2 float-start col-12 paging-container">
                            <button className="paging" onClick={() => handleFirstClick("2")}>
                              {t("GENERAL.FIRST")}
                            </button>
                            <button className="paging" onClick={() => handlePreviousClick("2")}>
                              {t("GENERAL.PREVIOUS")}
                            </button>
                            {generatePageNumbers("2").map((page) => (
                              <button
                                className={pagination["2"].currentPage === page ? "currpaging" : "paging"}
                                key={page}
                                onClick={() => setPageForList("2", page)}
                              >
                                {page}
                              </button>
                            ))}
                            <button className="paging" onClick={() => handleNextClick("2")}>
                              {t("GENERAL.NEXT")}
                            </button>
                            <button className="paging" onClick={() => handleLastClick("2")}>
                              {t("GENERAL.LAST")}
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* INCOMPLETED TAB */}

                      {/* READY TO FEED TAB */}
                      <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className="container-fluid pt-2 px-md-2 px-1">
                          <div className="">
                            <div className="float-start training-page-col-1 table-col-heading">&nbsp;</div>
                            <div className="float-start training-page-col-2 table-col-heading">SR.</div>
                            <div className="float-start training-page-col-3 table-col-heading">{t("TASKS.ISBN")}</div>
                            <div className="float-start training-page-col-4 table-col-heading">{t("TASKS.TITLE")}</div>
                            <div className="float-start training-page-col-5 table-col-heading">
                              {t("TRAINING.SEO_OPTIMIZED_TEXT")}
                            </div>
                            <div className="float-start training-page-col-6 text-end table-col-heading">
                              {t("GENERAL.ACTION")}
                            </div>
                          </div>
                          <div className="">
                            <div className="float-start w-100">
                              {loadingMsg && <div className="text-center w-100">{t("GENERAL.LOADING_DATA")}</div>}
                              {RTFproductList &&
                                RTFproductList.map((val) => {
                                  return (
                                    <>
                                      <div
                                        className="float-start pt-2 pb-2 w-100"
                                        style={{
                                          borderTop: "1px solid #0402020f",
                                        }}
                                      >
                                        <div className="float-start float-start training-page-col-1">
                                          <input
                                            id={val.id}
                                            checked={
                                              !!manualApproveOutputID[val.id] || !!manualApproveOutputIDSaved[val.id]
                                            }
                                            disabled={!!manualApproveOutputIDSaved[val.id]}
                                            onChange={(e) => handleCheckboxChange(val.id, e.target.checked)}
                                            type="checkbox"
                                            className="checkbox"
                                          />
                                        </div>
                                        <div className="float-start training-page-col-2">{val.serial_number}</div>
                                        <div className="float-start training-page-col-3">{val.product_isbn}</div>
                                        <div className="float-start training-page-col-4">{val.product_name}</div>
                                        <div className="float-start training-page-col-5">{nl2br(val.output)}</div>
                                        <div className="float-start text-end training-page-col-6">
                                          <i
                                            className="bi bi-info-circle topbar-icons"
                                            src={InfoIcon}
                                            onClick={() => viewProductDescription(val.product_description)}
                                            title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                            alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                          ></i>
                                          &nbsp;.&nbsp;
                                          <i
                                            className="bi bi-card-list topbar-icons"
                                            src={JobDesc}
                                            onClick={() => viewJobDescription(val.prompt, val.prompt2)}
                                            title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                            alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                          ></i>
                                        </div>
                                      </div>
                                      <div className="float-end d-block d-sm-none text-end pb-2">
                                        <i
                                          className="bi bi-journals topbar-icons"
                                          src={InfoIcon}
                                          onClick={() => viewProductDescription(nl2br(val.output))}
                                          title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                          alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                        ></i>
                                        &nbsp;.&nbsp;
                                        <i
                                          className="bi bi-info-circle topbar-icons"
                                          src={InfoIcon}
                                          onClick={() => viewProductDescription(val.product_description)}
                                          title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                          alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                        ></i>
                                        &nbsp;.&nbsp;
                                        <i
                                          className="bi bi-card-list topbar-icons"
                                          src={JobDesc}
                                          onClick={() => viewJobDescription(val.prompt, val.prompt2)}
                                          title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                          alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                        ></i>
                                      </div>
                                      <div class="clearfix"></div>
                                    </>
                                  )
                                })}
                            </div>
                          </div>
                          <div className="clearfix float-start col-12 paging-container">
                            <button className="paging" onClick={() => handleFirstClick("4")}>
                              {t("GENERAL.FIRST")}
                            </button>
                            <button className="paging" onClick={() => handlePreviousClick("4")}>
                              {t("GENERAL.PREVIOUS")}
                            </button>
                            {generatePageNumbers("3").map((page) => (
                              <button
                                className={pagination["3"].currentPage === page ? "currpaging" : "paging"}
                                key={page}
                                onClick={() => setPageForList("3", page)}
                              >
                                {page}
                              </button>
                            ))}
                            <button className="paging" onClick={() => handleNextClick("4")}>
                              {t("GENERAL.NEXT")}
                            </button>
                            <button className="paging" onClick={() => handleLastClick("4")}>
                              {t("GENERAL.LAST")}
                            </button>
                          </div>
                          <div className="">
                            <div className="col-6 pt-1 pb-1">
                              <div className="float-left w-auto ">
                                <button
                                  className="btn btn-md fm-btn me-md-2"
                                  onClick={() => saveManualOutputID()}
                                  type="button"
                                >
                                  {t("GENERAL.SAVE")}
                                </button>
                                {showsaveManualOutputMsg && showsaveManualOutputMsg}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* READY TO FEED TAB */}

                      {/* MOVED TO FEED TAB */}
                      <div class="tab-pane fade" id="pills-service" role="tabpanel" aria-labelledby="pills-service-tab">
                        <table className="table table-hover align-top">
                          <tbody>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">SR.</th>
                              <th scope="col">{t("TASKS.ISBN")}</th>
                              <th scope="col" className="col-2">
                                {t("TASKS.TITLE")}
                              </th>
                              <th scope="col">{t("TRAINING.SEO_OPTIMIZED_TEXT")}</th>
                              <th scope="col" align="right" style={{ textAlign: "right" }} width="10%">
                                {t("GENERAL.ACTION")}
                              </th>
                            </tr>
                            {loadingMsg && <div className="text-center w-100">{t("GENERAL.LOADING_DATA")}</div>}
                            {MTFproductList &&
                              MTFproductList.map((val) => {
                                return (
                                  <tr>
                                    <td valign="top"></td>
                                    <td>{val.serial_number}</td>
                                    <td>{val.product_isbn}</td>
                                    <td>{val.product_name}</td>
                                    <td colspan="2">
                                      <div>
                                        <div className="outputListItemOutputFirst">{nl2br(val.output)}</div>

                                        <div className="outputListItemAction">
                                          <img
                                            className="close-btn"
                                            src={InfoIcon}
                                            onClick={() => viewProductDescription(val.product_description)}
                                            title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                            alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                            width="18"
                                          />
                                          &nbsp;.&nbsp;
                                          <img
                                            className="close-btn"
                                            src={JobDesc}
                                            onClick={() => viewJobDescription(val.prompt, val.prompt2)}
                                            title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                            alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                            width="18"
                                          />
                                        </div>
                                        <div className="outputListItemAction">&nbsp;</div>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                          <tr>
                            <td colspan="5">
                              <button className="paging" onClick={() => handleFirstClick("4")}>
                                {t("GENERAL.FIRST")}
                              </button>
                              <button className="paging" onClick={() => handlePreviousClick("4")}>
                                {t("GENERAL.PREVIOUS")}
                              </button>
                              {generatePageNumbers("4").map((page) => (
                                <button
                                  className={pagination["4"].currentPage === page ? "currpaging" : "paging"}
                                  key={page}
                                  onClick={() => setPageForList("4", page)}
                                >
                                  {page}
                                </button>
                              ))}
                              <button className="paging" onClick={() => handleNextClick("4")}>
                                {t("GENERAL.NEXT")}
                              </button>
                              <button className="paging" onClick={() => handleLastClick("4")}>
                                {t("GENERAL.LAST")}
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                      {/* MOVED TO FEED TAB */}
                    </div>
                  </div>
                </div>
              </div>
              {showDescModal && (
                <div className="modal display-block">
                  <div className="modal-main">
                    <div
                      style={{
                        float: "right",
                        marginTop: "-15px",
                        marginRight: "-5px",
                      }}
                    >
                      <img
                        src={CloseIcon}
                        onClick={handleCloseProdDesc}
                        alt={t("GENERAL.CLOSE")}
                        className="close-btn"
                        width="12"
                      />
                    </div>
                    <div className="modal-contentt">{productDescription && productDescription}</div>
                  </div>
                </div>
              )}
              {showModal && (
                <div className="modal display-block">
                  <div className="modal-main">
                    <div
                      style={{
                        float: "right",
                        marginTop: "-15px",
                        marginRight: "-5px",
                      }}
                    >
                      <img
                        src={CloseIcon}
                        onClick={handleClose}
                        alt={t("GENERAL.CLOSE")}
                        className="close-btn"
                        width="12"
                      />
                    </div>
                    <div className="modal-contentt">
                      {jobDescription &&
                        jobDescription.map((value) => {
                          return (
                            <>
                              <table className="table table-hover align-middle">
                                <tbody>
                                  <tr>
                                    <td colspan="2">
                                      <b>{value.question}</b>
                                    </td>
                                  </tr>
                                  {value.answers &&
                                    value.answers.map((ans) => {
                                      return (
                                        <>
                                          <tr>
                                            {ans.answer_heading && (
                                              <td className="col-4">
                                                <input
                                                  readonly="true"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  aria-label=""
                                                  aria-describedby=""
                                                  value={ans.answer_heading}
                                                />
                                              </td>
                                            )}
                                            <td>
                                              <input
                                                readonly="true"
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                aria-label=""
                                                aria-describedby=""
                                                value={ans.answer}
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    })}
                                </tbody>
                              </table>
                            </>
                          )
                        })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default CompletedTask
