import httpClient from "../utils/httpClientSetup"

class AssistantService {
  assistantsList(orgID) {
    const url = `${process.env.REACT_APP_API_SERVER_URL}assistantslist?organization_id=${orgID}`

    return httpClient
      .get(url, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        return response
      })
  }

  saveSeoFrequency(
    orgID,
    assistantID,
    frequency,
    days,
    time,
    attributeIDs,
    schedulenow,
    selectedDate,
    includeall,
    totalProducts,
    savedWorkPlanID
  ) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgID,
        assistant_id: assistantID,
        frequency: frequency,
        days: days,
        time: time,
        attribute_ids: attributeIDs,
        schedulenow: schedulenow,
        cron_start_date: selectedDate,
        include_all: includeall,
        total_products: totalProducts,
        work_plan_id: savedWorkPlanID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "saveSeoFrequency",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  setSEOCriteriaAndSchedule(orgID, assistantID, time, attributeIDs) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        organization_id: orgID,
        assistant_id: assistantID,
        execution_time: time,
        attribute_ids: attributeIDs,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "setseocriteriaandschedule",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  getSEOCriteriaAndSchedule(assistantID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { assistant_id: assistantID },
      url: process.env.REACT_APP_API_SERVER_URL + "getseocriteriaandschedule",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  getAttributeGroupValuesById(attributeId) {
    const data = {
      attribute_id: attributeId,
    }

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data,
      url: process.env.REACT_APP_API_SERVER_URL + "attributegroupvaluesbyid",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  getAttributeGroupNameById(groupID) {
    const data = {
      id: groupID,
    }

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data,
      url: process.env.REACT_APP_API_SERVER_URL + "attributegroupnamebyid",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  workPlanFilterCount(attributeId, assistantID, orgID) {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url:
        process.env.REACT_APP_API_SERVER_URL +
        "workPlanFilterCount?attribute_id=" +
        JSON.stringify(attributeId) +
        "&assistant_id=" +
        assistantID +
        "&organization_id=" +
        orgID,
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  workPlanSecondaryFilters(groupID, parentAttributes) {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url:
        process.env.REACT_APP_API_SERVER_URL +
        "workPlanSecondaryFilters?group_id=" +
        groupID +
        "&parent_attribute=" +
        parentAttributes,
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  getWorkPlanList(assistantID, orgID) {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { assistant_id: assistantID, organization_id: orgID },
      url: process.env.REACT_APP_API_SERVER_URL + "workplanlist",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  getAttributeNamesByID(attributeID) {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { id: attributeID },
      url: process.env.REACT_APP_API_SERVER_URL + "attributesnamebyid",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  stopResumeWorkPlan(workPlanID, stopresume) {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { id: workPlanID, status: stopresume },
      url: process.env.REACT_APP_API_SERVER_URL + "stopresumeworkplan",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  getConsumedCredits(assistantID, organizationId) {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url:
        process.env.REACT_APP_API_SERVER_URL +
        "getConsumedCredits?assistant_id=" +
        assistantID +
        "&organization_id=" +
        organizationId,
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  startAgainWorkPlanStats(workPlanID) {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { id: workPlanID },
      url: process.env.REACT_APP_API_SERVER_URL + "startagainworkplanstats",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }
}
const assistantServiceInstance = new AssistantService()
export default assistantServiceInstance
