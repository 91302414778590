import React, { useEffect, useState } from "react"
import AssistantService from "../services/AssistantService"
import ProductService from "../services/ProductService"
import CreditBar from "./CreditBar"
import TopBar from "./Topbar"
import { useTranslation } from "react-i18next"
import { Dropdown } from "semantic-ui-react"
import MyLoader from "./MyLoader"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { nb } from "date-fns/locale"
import { useParsedAssistantIdFromParams } from "../utils/utils"
import PIMServicesInstance from "../services/PIMServices"

function WorkPlan() {
  const { assistantID } = useParsedAssistantIdFromParams()

  const formatDateTime = (date) => {
    if (!date) return ""
    return date
      .toLocaleString("no-NO", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(" ", "T")
  }
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [scheduleISBN, setScheduleISBN] = useState("")
  const [dailyWeeklyMonthly, setDailyWeeklyMonthly] = useState()
  const [monthDay, setMonthDay] = useState()
  const showMassSEOCriteria = useState(true)
  const [selectedTime, setSelectedTime] = useState()
  const [saveFrequencyMsg, setSaveFrequencyMsg] = useState()
  const [primaryAttributeList, setPrimaryAttributeList] = useState([])
  const [secondaryAttributeList, setSecondaryAttributeList] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedOptions, setSelectedOptions] = useState({})
  const [showCriteriaStats, setShowCriteriaStats] = useState(false)
  const [statsSEOGenerated, setStatsSEOGenerated] = useState("")
  const [totalProductsInCriteria, setTotalProductsInCriteria] = useState("")
  const [showAutoSEONextStep, setShowAutoSEONextStep] = useState(false)
  const [showManualSEONextStep, setShowManualSEONextStep] = useState(false)
  const [includeAllCriteria, setIncludeAllCriteria] = useState("no")
  const [atrributeItemWaiting, setAtrributeItemWaiting] = useState(false)
  const [statsNotSEOGenerated, setStatsNotSEOGenerated] = useState(0)
  const [totalCredit, setTotalCredit] = useState()
  const [creditUsed, setCreditUsed] = useState()
  const [showScheduleForLater, setShowScheduleForLater] = useState(false)
  const [SEOGenerateChanged, setSEOGenerateChanged] = useState("no")
  const [savedWorkPlanID, setSavedWorkPlanID] = useState(0)
  const [primaryAttributeID, setPrimaryAttributeID] = useState("")
  const [allAttributGroupIDs, setAllAttributGroupIDs] = useState([])
  useEffect(() => {
    setSaveFrequencyMsg("")
    PIMServicesInstance.getPIMFrequency(organizationID).then((response) => {
      const primaryID = response.attributes[0].id
      setPrimaryAttributeID(primaryID)
      const secondaryID = response.pimFrequency.attribute_ids.split(",").map((id) => parseInt(id.trim(), 10))
      setAllAttributGroupIDs(secondaryID)

      secondaryID
        .filter((id) => id !== primaryID)
        .forEach((attribID, keys) => {
          AssistantService.getAttributeGroupValuesById(attribID).then((responsee) => {
            setSecondaryAttributeList((prevList) => {
              const newGroup = {
                key: keys,
                id: attribID,
                name: responsee.data.groupname,
                items: [
                  { value: parseInt(primaryID + "0000000000", 10), text: "All" },
                  ...(Array.isArray(responsee.data.attributes)
                    ? responsee.data.attributes.map((val) => ({
                        value: val.id,
                        text: val.attribute_value,
                      }))
                    : []),
                ],
              }
              const updatedList = prevList.filter((group) => group.id !== attribID)
              return [...(updatedList || []), newGroup]
            })
          })
        })
      AssistantService.getAttributeGroupValuesById(primaryID).then((responsee) => {
        setPrimaryAttributeList({
          id: primaryID,
          name: responsee.data.groupname,
          items: [
            { value: parseInt(primaryID + "0000000000", 10), text: "All" },
            ...(Array.isArray(responsee.data.attributes)
              ? responsee.data.attributes.map((val) => ({
                  value: val.id,
                  text: val.attribute_value,
                }))
              : []),
          ],
        })
      })
    })
    AssistantService.getConsumedCredits(assistantID, organizationID).then((assistresponse) => {
      setTotalCredit(assistresponse.credits)
      setCreditUsed(assistresponse.consumed)
    })
  }, [organizationID, showCriteriaStats, assistantID])
  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  })
  const selectWeeklyDays = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      ...day,
    }))
  }

  const days = Array.from({ length: 31 }, (_, index) => index + 1)
  const handleMonthDayChange = (event) => {
    setMonthDay(event.target.value)
  }

  const handleSelectChange = (event) => {
    setSelectedDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    })
    setDailyWeeklyMonthly(event.target.value)
  }
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, "0")
    return `${hour}:00`
  })
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value + ":00")
  }
  const { t } = useTranslation()

  const saveSEOFrequency = (schedulenow) => {
    const params = {
      frequency: dailyWeeklyMonthly,
      days: dailyWeeklyMonthly === "weekly" ? JSON.stringify(selectedDays) : monthDay,
      time: selectedTime,
    }
    AssistantService.saveSeoFrequency(
      organizationID,
      assistantID,
      dailyWeeklyMonthly,
      params.days,
      params.time,
      selectedOptions,
      schedulenow,
      formatDateTime(selectedDate),
      includeAllCriteria,
      statsNotSEOGenerated,
      savedWorkPlanID
    ).then((response) => {
      setSaveFrequencyMsg(t("WORK_PLAN.INFO_SAVED"))
      setSavedWorkPlanID(response.workplanid)
    })
  }

  const handleChange = (event) => {
    setScheduleISBN(event.target.value)
  }

  const addISBNSchedule = () => {
    ProductService.addSchedule(organizationID, assistantID, formatDateTime(selectedDate), scheduleISBN).then(() => {
      setSaveFrequencyMsg(t("WORK_PLAN.INFO_SAVED"))
    })
  }

  const handlePrimaryDropDownChange = (attributeData, attribGroupID) => {
    setSelectedOptions({})
    setSelectedOptions((prevState) => {
      const updatedOptions = { ...prevState }
      updatedOptions[attribGroupID] = attributeData.value

      AssistantService.workPlanFilterCount(updatedOptions, assistantID, organizationID)
        .then((response) => {
          setTotalProductsInCriteria(response.data.totalProducts)
          setStatsSEOGenerated(response.data.totalSeoProducts)
          setStatsNotSEOGenerated(response.data.totalProducts - response.data.totalSeoProducts)
          setShowCriteriaStats(true)
          setAtrributeItemWaiting(true)
          setSecondaryAttributeList([])

          allAttributGroupIDs
            .filter((id) => id !== primaryAttributeID)
            .forEach((attribID, keys) => {
              if (attributeData.value.length === 0) {
                setShowCriteriaStats(false)

                return
              }
              AssistantService.workPlanSecondaryFilters(attribID, attributeData.value).then((secFilterResponse) => {
                if (attributeData.value === "" && attribID === primaryAttributeID) {
                  setSecondaryAttributeList((prevList) => {
                    const newGroup = {
                      key: keys,
                      id: secFilterResponse.data.attribute_group,
                      name: secFilterResponse.data.attribute_groupname,
                      items: "",
                    }
                    const updatedList = prevList.filter((group) => group.id !== attribID)
                    return [...(updatedList || []), newGroup]
                  })
                } else {
                  setSecondaryAttributeList((prevList) => {
                    const attributes = secFilterResponse.data.attributes
                    const newGroup = {
                      key: keys,
                      id: secFilterResponse.data.attribute_group,
                      name: secFilterResponse.data.attribute_groupname,
                      items: [
                        {
                          value: parseInt(`${secFilterResponse.data.attribute_group}0000000000`, 10),
                          text: "All",
                        },
                        ...(attributes
                          ? Object.values(attributes).map((attr) => ({
                              value: attr.attribute_value,
                              text: attr.attribute_name,
                            }))
                          : []),
                      ],
                    }
                    const updatedList = prevList.filter((group) => group.id !== attribID)
                    return [...(updatedList || []), newGroup]
                  })
                }
              })
            })

          setAtrributeItemWaiting(false)
        })
        .catch((error) => {
          setShowCriteriaStats(false)
          document.getElementById("seostats-text").value = ""
        })

      return updatedOptions
    })

    document.getElementById("seostats-text").value = attributeData.value
  }
  const handleSecondaryDropDownChange = (data, attribGroupID) => {
    setSelectedOptions((prevState) => {
      const updatedState = { ...prevState }
      updatedState[attribGroupID] = data.value // Assuming `data.value` contains the selected values for the group
      AssistantService.workPlanFilterCount(updatedState, assistantID, organizationID).then((response) => {
        setStatsNotSEOGenerated(response.data.totalProducts - response.data.seoGeneratedCount)
        setStatsSEOGenerated(response.data.totalSeoProducts)
        setTotalProductsInCriteria(response.data.totalProducts)

        setShowCriteriaStats(true)
      })
      return updatedState
    })
    document.getElementById("seostats-text").value = data.value
  }
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">{t("WORK_SCHEDULE.SCHEDULE")}</h1>
                <div className="breadcrumb">{t("WORK_SCHEDULE.SCHEDULE")}</div>
              </div>
              <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
              <TopBar />
            </div>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
              <div className="col-md-3 col-lg-8 px-4 ">
                <p>{t("WORK_SCHEDULE.PAGE_SUBLINE")}</p>
              </div>
            </div>
            <div className="container-fluid">
              <hr />
            </div>
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="">
                  <h4 className="h4">{t("WORK_SCHEDULE.SET_UP")}</h4>
                </div>
              </div>

              {showAutoSEONextStep || showManualSEONextStep ? (
                <>
                  <div className="row">
                    <div className="mt-4">
                      {showAutoSEONextStep && (
                        <>
                          {totalCredit - creditUsed > totalProductsInCriteria - statsSEOGenerated ? (
                            <>
                              {t("WORK_PLAN.WILL_CONSUME")}{" "}
                              {includeAllCriteria === "yes"
                                ? totalProductsInCriteria
                                : totalProductsInCriteria - statsSEOGenerated}{" "}
                              {t("WORK_PLAN.CREDITS")}
                            </>
                          ) : (
                            <>
                              <p className="red">
                                {t("WORK_PLAN.REQUIRES")}{" "}
                                {includeAllCriteria === "yes"
                                  ? totalProductsInCriteria
                                  : totalProductsInCriteria - statsSEOGenerated}{" "}
                                {t("WORK_PLAN.CREDITS")}. {t("WORK_PLAN.HAVE")} {totalCredit - creditUsed}{" "}
                                {t("WORK_PLAN.AVAILABLE")}.
                              </p>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="float-left w-auto pl-0 ">
                      <input
                        name="cron_start_date"
                        checked={showScheduleForLater === true ? "" : "checked"}
                        value="now"
                        onClick={() => {
                          setShowScheduleForLater(false)
                          setSelectedDate(new Date())
                        }}
                        disabled={
                          totalCredit - creditUsed > totalProductsInCriteria - statsSEOGenerated ? "" : "disabled"
                        }
                        type="radio"
                        className="checkbox"
                      />
                    </div>
                    <div className="float-left w-auto p-0 lh-sm">{t("WORK_PLAN.START_NOW")}</div>
                  </div>
                  <div className="row mt-4">
                    <div className="float-left w-auto pl-0 ">
                      <input
                        name="cron_start_date"
                        value="later"
                        checked={showScheduleForLater === true && "checked"}
                        onClick={() => setShowScheduleForLater(true)}
                        disabled={
                          totalCredit - creditUsed > totalProductsInCriteria - statsSEOGenerated ? "" : "disabled"
                        }
                        type="radio"
                        className="checkbox"
                      />
                    </div>
                    <div className="float-left w-auto p-0 lh-sm">{t("WORK_PLAN.SCHEDULE_FOR_LATER")}</div>
                    <div className="float-left w-auto pl-0 " style={{ marginTop: "-9px" }}>
                      {showScheduleForLater && (
                        <>
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            timeCaption="Time"
                            locale={nb}
                            className="form-control"
                            dateFormat="MMMM d, yyyy HH:mm"
                            placeholderText="Select a date and time"
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="float-left w-auto pl-0 ">
                      <input
                        type="checkbox"
                        value="yes"
                        checked={SEOGenerateChanged === "yes" && "checked"}
                        onChange={(e) => {
                          setSEOGenerateChanged(e.target.checked ? "yes" : "no")
                        }}
                        name="continousseo"
                        className="checkbox"
                      />
                    </div>
                    <div className="float-left w-auto p-0 lh-sm">{t("WORK_PLAN.CONTINOUS_GENERATION")}</div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-8 px-5 h6">{t("WORK_PLAN.CHOOSE_INTERVAL_SUBLINE")}</div>
                  </div>
                  {SEOGenerateChanged === "yes" && (
                    <>
                      <div className="row mt-3">
                        <div className="col-1">
                          <br />
                          <select className="form-control" onChange={handleSelectChange}>
                            <option value="">{t("GENERAL.SELECT")}</option>
                            <option value="hourly" selected={dailyWeeklyMonthly === "hourly" && "selected"}>
                              {t("SCHEDULING.HOURLY")}
                            </option>
                            <option value="daily" selected={dailyWeeklyMonthly === "daily" && "selected"}>
                              {t("SCHEDULING.DAILY")}
                            </option>
                            <option value="weekly" selected={dailyWeeklyMonthly === "weekly" && "selected"}>
                              {t("SCHEDULING.WEEKLY")}
                            </option>
                            <option value="monthly" selected={dailyWeeklyMonthly === "monthly" && "selected"}>
                              {t("SCHEDULING.MONTHLY")}
                            </option>
                          </select>
                        </div>

                        <div className="col-4">
                          {dailyWeeklyMonthly === "hourly" ? (
                            <></>
                          ) : dailyWeeklyMonthly === "daily" ? (
                            <div className="row">
                              <div className="col-3">
                                {t("SCHEDULING.TIME")}
                                <select className="form-control" onChange={handleTimeChange}>
                                  {times.map((time, index) => (
                                    <option
                                      selected={selectedTime === time + ":00" && "selected"}
                                      key={index}
                                      value={time}
                                    >
                                      {time}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : dailyWeeklyMonthly === "weekly" ? (
                            <div className="row">
                              <div className="float-left w-auto px-3">
                                <div className="row">{t("SCHEDULING.WEEKDAY")}</div>
                                <div className="row">
                                  <div
                                    className={`col-1 p-0 weeklydays ml-1 ${selectedDays.monday && "selectedDay"}`}
                                    onClick={() =>
                                      selectWeeklyDays({
                                        monday: !selectedDays.monday,
                                      })
                                    }
                                  >
                                    {t("SCHEDULING.MON")}
                                  </div>
                                  <div
                                    className={`col-1 p-0 weeklydays ml-1 ${selectedDays.tuesday && "selectedDay"}`}
                                    onClick={() =>
                                      selectWeeklyDays({
                                        tuesday: !selectedDays.tuesday,
                                      })
                                    }
                                  >
                                    {t("SCHEDULING.TUE")}
                                  </div>
                                  <div
                                    className={`col-1 p-0 weeklydays ml-1 ${selectedDays.wednesday && "selectedDay"}`}
                                    onClick={() =>
                                      selectWeeklyDays({
                                        wednesday: !selectedDays.wednesday,
                                      })
                                    }
                                  >
                                    {t("SCHEDULING.WED")}
                                  </div>
                                  <div
                                    className={`col-1 p-0 weeklydays ml-1 ${selectedDays.thursday && "selectedDay"}`}
                                    onClick={() =>
                                      selectWeeklyDays({
                                        thursday: !selectedDays.thursday,
                                      })
                                    }
                                  >
                                    {t("SCHEDULING.THU")}
                                  </div>
                                  <div
                                    className={`col-1 p-0 weeklydays ml-1 ${selectedDays.friday && "selectedDay"}`}
                                    onClick={() =>
                                      selectWeeklyDays({
                                        friday: !selectedDays.friday,
                                      })
                                    }
                                  >
                                    {t("SCHEDULING.FRI")}
                                  </div>
                                  <div
                                    className={`col-1 p-0 weeklydays ml-1 ${selectedDays.saturday && "selectedDay"}`}
                                    onClick={() =>
                                      selectWeeklyDays({
                                        saturday: !selectedDays.saturday,
                                      })
                                    }
                                  >
                                    {t("SCHEDULING.SAT")}
                                  </div>
                                  <div
                                    className={`col-1 p-0 weeklydays ml-1 ${selectedDays.sunday && "selectedDay"}`}
                                    onClick={() =>
                                      selectWeeklyDays({
                                        sunday: !selectedDays.sunday,
                                      })
                                    }
                                  >
                                    {t("SCHEDULING.SUN")}
                                  </div>
                                </div>
                              </div>
                              <div className="float-left w-25">
                                <div className="row">{t("SCHEDULING.TIME")}</div>
                                <div className="row">
                                  <div className="col-12 p-0">
                                    <select className="form-control" onChange={handleTimeChange}>
                                      {times.map((time, index) => (
                                        <option
                                          selected={selectedTime === time + ":00" && "selected"}
                                          key={index}
                                          value={time}
                                        >
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            dailyWeeklyMonthly === "monthly" && (
                              <div className="row">
                                <div className="col-3">
                                  {t("SCHEDULING.DATE")}
                                  <select className="form-control" onChange={handleMonthDayChange}>
                                    <option value="">{t("GENERAL.SELECT")}</option>
                                    {days.map((day) => (
                                      <option key={day} value={day} selected={monthDay === day && "selected"}>
                                        {day}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-3">
                                  {t("SCHEDULING.TIME")}
                                  <select className="form-control" onChange={handleTimeChange}>
                                    {times.map((time, index) => (
                                      <option
                                        selected={selectedTime === time + ":00" && "selected"}
                                        key={index}
                                        value={time}
                                      >
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row pt-4">
                    <div className="col-12">
                      {showAutoSEONextStep && (
                        <>
                          <button
                            disabled={
                              totalCredit - creditUsed > totalProductsInCriteria - statsSEOGenerated ? "" : "disabled"
                            }
                            className="btn btn-md fm-btn me-md-2"
                            type="button"
                            onClick={() => saveSEOFrequency("schedule")}
                          >
                            {t("GENERAL.SAVE")}
                          </button>
                        </>
                      )}

                      {saveFrequencyMsg && saveFrequencyMsg}
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-12">
                      <button
                        className="btn btn-md fm-btn me-md-2"
                        type="button"
                        onClick={() => {
                          setShowAutoSEONextStep(false)
                          setSaveFrequencyMsg("")
                          setShowManualSEONextStep(false)
                        }}
                      >
                        {t("WORK_PLAN.PREVIOUS_STEP")}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="mt-4">
                      <h6 className="h5">{t("WORK_PLAN.SELECT_MASS_UPDATE_CRITERIA")}</h6>
                    </div>
                  </div>

                  {showMassSEOCriteria && (
                    <>
                      <div className="row pt-4">
                        <div className="col-12 col-lg-6">
                          <div className="row pt-2">
                            <div className="col-4 col-lg-3 attribute-label">
                              {primaryAttributeList.name && primaryAttributeList.name + " :"}
                            </div>
                            <div className="col-8 col-lg-9">
                              {primaryAttributeList.items && (
                                <Dropdown
                                  value={selectedOptions[primaryAttributeList.id] || []}
                                  options={primaryAttributeList.items}
                                  onChange={(_, data) => handlePrimaryDropDownChange(data, primaryAttributeList.id)}
                                  placeholder={"Velge " + primaryAttributeList.name}
                                  fluid
                                  search
                                  multiple
                                  clearable
                                  selection
                                />
                              )}
                            </div>
                          </div>

                          {atrributeItemWaiting ? (
                            <>
                              <MyLoader />
                            </>
                          ) : (
                            secondaryAttributeList &&
                            secondaryAttributeList
                              .sort((a, b) => a.key - b.key)
                              .map((value) => {
                                return (
                                  <>
                                    <div className="row pt-2">
                                      <div className="col-4 col-lg-3 attribute-label">{value.name + " :"}</div>
                                      <div className="col-8 col-lg-9">
                                        <Dropdown
                                          value={selectedOptions[value.id] || []}
                                          options={value.items}
                                          onChange={(_, data) => handleSecondaryDropDownChange(data, value.id)}
                                          placeholder={"Velge " + value.name}
                                          fluid
                                          search
                                          multiple
                                          clearable
                                          selection
                                        />
                                      </div>
                                    </div>
                                  </>
                                )
                              })
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 pt-4">
                          {showCriteriaStats && totalProductsInCriteria && (
                            <>
                              <div className="col-12 col-lg-6">
                                <div>
                                  {t("WORK_PLAN.THERE_ARE")} {totalProductsInCriteria - statsSEOGenerated}{" "}
                                  {t("WORK_PLAN.FITTING_PRODUCTS")}
                                </div>
                                <div className="row mt-2">
                                  <div className="float-left pl-0 w-auto">
                                    <input
                                      className="checkbox"
                                      type="checkbox"
                                      checked={includeAllCriteria === "yes" && "checked"}
                                      value={includeAllCriteria}
                                      onChange={(e) => {
                                        setStatsNotSEOGenerated(
                                          e.target.checked
                                            ? totalProductsInCriteria
                                            : totalProductsInCriteria - statsSEOGenerated
                                        )
                                        setIncludeAllCriteria(e.target.checked ? "yes" : "no")
                                      }}
                                    />
                                    &nbsp;
                                  </div>
                                  <div className="float-left p-0 w-auto">
                                    {t("WORK_PLAN.INCLUDE_OPTIMIZED_PRODUCTS")} ({statsSEOGenerated}{" "}
                                    {t("WORK_PLAN.OPTIMIZED")}, {t("WORK_PLAN.TOTAL")} {totalProductsInCriteria})
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-6">
                            <input size="50" type="hidden" id="seostats-text" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 pt-4">
                          <div className="col-12">
                            <button
                              className="btn btn-md fm-btn me-md-2"
                              type="button"
                              onClick={() => setShowAutoSEONextStep(true)}
                            >
                              {t("WORK_PLAN.NEXT_STEP")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            {showAutoSEONextStep || showManualSEONextStep ? (
              <></>
            ) : (
              <>
                <div className="container-fluid mt-4">
                  <hr />
                </div>
                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12 col-lg-6 pt-4">
                      <div className="col-12">
                        <h5>{t("WORK_PLAN.SCHEDULE_BY_PRODUCT")}</h5>
                      </div>
                      <div className="col-12">
                        <div className="input-group mb-3">
                          <textarea className="form-control" onChange={handleChange}></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 pt-4">
                      <div className="col-12">
                        <button
                          disabled={scheduleISBN.length > 0 ? "" : "disabled"}
                          onClick={() => addISBNSchedule("now")}
                          className="btn btn-md fm-btn me-md-2"
                        >
                          {t("WORK_PLAN.START_NOW")}
                        </button>
                        {saveFrequencyMsg && saveFrequencyMsg}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </main>
        </div>
      </div>
    </div>
  )
}
export default WorkPlan
