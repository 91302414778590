import { NavLink } from "react-router-dom"
import React, { useEffect, useRef, useState } from "react"
import AssistantService from "../services/AssistantService"
import PromptService from "../services/PromptService"
import CreditBar from "./CreditBar"
import TopBar from "./Topbar"
import Information from "../images/info_outline.png"
import { useTranslation } from "react-i18next"
import { useParsedAssistantIdFromParams } from "../utils/utils"

function JobDescription() {
  const { assistantID } = useParsedAssistantIdFromParams()
  const { t } = useTranslation()
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [totalCredit, setTotalCredit] = useState()
  const [creditUsed, setCreditUsed] = useState()
  const [questionsList, setQuestionsList] = useState()
  const [answerData, setAnswerData] = useState([])
  useEffect(() => {
    PromptService.getAssistantQuestions(assistantID).then((response) => {
      setQuestionsList(response.questions)
      if (!response.questions) {
        return
      }
      response.questions.forEach((question) => {
        fetchAnswers(question.id)
      })
    })

    AssistantService.getConsumedCredits(assistantID, organizationID).then((assistresponse) => {
      setTotalCredit(assistresponse.credits)
      setCreditUsed(assistresponse.consumed)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID, assistantID])

  const fetchAnswers = async (questionID) => {
    try {
      PromptService.getAnswersByQuestion(questionID, organizationID).then((response) => {
        setAnswerData((prevData) => ({
          ...prevData,
          [questionID]: response.answers,
        }))
      })
    } catch (error) {}
  }

  const HelpBubble = ({ text }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false)
    const bubbleRef = useRef(null)

    const toggleTooltip = () => {
      setTooltipVisible(!isTooltipVisible)
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (bubbleRef.current && !bubbleRef.current.contains(event.target)) {
          setTooltipVisible(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [])

    return (
      <div className="help-container" ref={bubbleRef}>
        <img className="help-bubble" onClick={toggleTooltip} src={Information} alt="help-bubble" />
        {isTooltipVisible && <div className="tooltip">{text}</div>}
      </div>
    )
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">{t("JOBS.DESCRIPTION_FOR_ASSISTANT")}</h1>
                <div className="breadcrumb">{t("JOBS.DESCRIPTION_FOR_ASSISTANT")}</div>
              </div>
              <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
              <TopBar />
            </div>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
              <div className="col-md-3 col-lg-8 px-4 ">
                <p>{t("JOBS.DESCRIPTION_PAGE_SUBLINE")}</p>
              </div>
            </div>

            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12 p-4">
                  {questionsList &&
                    questionsList.map((values, keys) => {
                      return (
                        <>
                          <div className="col-12 job_desc_question_label">
                            <br />
                            <table className="table table-hover pb-0 align-middle">
                              <tbody>
                                <tr className="tr-header">
                                  <td width="auto">
                                    {values.question} <HelpBubble text={values.help} />
                                  </td>
                                  <td align="left"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12">
                            <div className="responsive-container">
                              {answerData[values.id] ? (
                                answerData[values.id].map((value, key) => (
                                  <div key={key} className="row align-items-center">
                                    {values.type === "twocolumn" && (
                                      <div className="col-12 col-lg-2">
                                        <input
                                          readOnly
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={value.answer_heading}
                                        />
                                      </div>
                                    )}
                                    <div className={values.type === "twocolumn" ? "col-12 col-lg-10" : "col-12"}>
                                      <input
                                        readOnly
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={value.answer}
                                      />
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <>
                                  <br />
                                  <br />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )
                    })}
                </div>

                <div className="col-12 p-4 text-center text-sm-start">
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                    <NavLink to={"/" + assistantID + "/history"}>
                      <button className="btn btn-lg fm-btn me-md-2" type="button">
                        {t("JOBS.PREVIOUS_DESCRIPTIONS")}
                      </button>{" "}
                    </NavLink>
                    <NavLink to={"/" + assistantID + "/improve"}>
                      <button className="btn btn-lg fm-btn" type="button">
                        {t("JOBS.MANAGE_DESCRIPTIONS")}
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default JobDescription
