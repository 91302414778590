import React from "react"
import CloseCircle from "../images/cross-circle.png"
import { useTranslation } from "react-i18next"

function DashboardSection({ rows, setRows, title }) {
  const { t } = useTranslation()
  const addNewRow = () => {
    const newRow = { id: rows.length + 1, heading: "", description: "" }
    setRows([...rows, newRow])
  }

  const removeRow = (id) => {
    const newRows = rows
      .filter((row) => row.id !== id)
      .map((row, index) => ({
        ...row,
        id: index + 1,
      }))
    setRows(newRows)
  }

  return (
    <>
      <div className="col-12">
        <table className="table align-middle">
          <tbody>
            <tr>
              <td>
                <h3>{title}</h3>
              </td>
              <td className="col-6 col-lg-2" align="right">
                <button className="btn fm-btn" onClick={() => addNewRow()} type="button">
                  {t("GENERAL.ADD")}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 table-responsive mt-4 fm-table">
        <div className="container-fluid px-0 px-md-3 ">
          <div className="row fw-bold mb-2 tr-header">
            <div className="col-1 ">&nbsp;</div>
            <div className="col-3  d-none d-md-block">{t("DASHBOARD_SECTION.HEADING")}</div>
            <div className="col-7 d-none d-md-block">{t("DASHBOARD_SECTION.EXPLANATION")}</div>
            <div className="col-1 text-end"></div>
          </div>
          {rows.map((row, index) => (
            <div className="row align-items-center align-items-start mb-3" key={row.id}>
              {/* ID Column */}
              <div className="col-12 d-none d-md-block align-items-start  col-md-1">
                {String(row.id).padStart(2, "0")}
              </div>

              <div className="col-12 d-block d-md-none fw-bold">{t("DASHBOARD_SECTION.HEADING")}</div>
              <div className="col-12 col-md-3">
                <input
                  type="text"
                  name={`heading_${row.id}`}
                  className="form-control"
                  placeholder=""
                  value={row.heading}
                  onChange={(e) => {
                    const newRows = [...rows]
                    newRows[index].heading = e.target.value
                    setRows(newRows)
                  }}
                />
              </div>

              <div className="col-12 d-block d-md-none fw-bold">{t("DASHBOARD_SECTION.EXPLANATION")}</div>
              <div className="col-12 align-items-start  col-md-7">
                <textarea
                  name={`description_${row.id}`}
                  className="form-control"
                  style={{ resize: "none" }}
                  placeholder=""
                  value={row.description}
                  onChange={(e) => {
                    const newRows = [...rows]
                    newRows[index].description = e.target.value
                    setRows(newRows)
                  }}
                ></textarea>
              </div>

              {/* Remove Button */}
              <div className="col-12 col-md-1 text-end">
                <img
                  className="close-btn"
                  src={CloseCircle}
                  title={t("GENERAL.REMOVE")}
                  onClick={() => removeRow(row.id)}
                  alt="Close"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-12 table-responsive mt-4 fm-table">
        <table className="table table-hover d-none align-middle">
          <tbody>
            <tr className="tr-header">
              <td>&nbsp;</td>
              <td>
                <b>{t("DASHBOARD_SECTION.HEADING")}</b>
              </td>
              <td>
                <b>{t("DASHBOARD_SECTION.EXPLANATION")}</b>
              </td>
              <td></td>
            </tr>
            {rows.map((row, index) => (
              <tr key={row.id}>
                <td>{String(row.id).padStart(2, "0")}</td>
                <td className="col-2 " valign="top">
                  <input
                    type="text"
                    name={`heading_${row.id}`}
                    className="form-control"
                    placeholder=""
                    aria-label=""
                    aria-describedby=""
                    value={row.heading}
                    onChange={(e) => {
                      const newRows = [...rows]
                      newRows[index].heading = e.target.value
                      setRows(newRows)
                    }}
                  />
                </td>
                <td>
                  <textarea
                    name={`description_${row.id}`}
                    style={{ resize: "none" }}
                    className="form-control"
                    placeholder=""
                    aria-label=""
                    aria-describedby=""
                    value={row.description}
                    onChange={(e) => {
                      const newRows = [...rows]
                      newRows[index].description = e.target.value
                      setRows(newRows)
                    }}
                  ></textarea>
                </td>
                <td align="right" className="col-1">
                  <img
                    className="close-btn"
                    src={CloseCircle}
                    title={t("GENERAL.REMOVE")}
                    onClick={() => removeRow(row.id)}
                    alt="Close"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default DashboardSection
