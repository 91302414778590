import { BrowserRouter, Route, Routes } from "react-router-dom"
import React from "react"
import SideBar from "./components/Sidebar"
import Login from "./components/Login"
import Dashboard from "./components/Dashboard"
import Tools from "./components/Tools"
import Organizations from "./components/Organizations"
import AddOrganization from "./components/AddOrganization"
import JobDescription from "./components/JobDescription"
import JobInstruction from "./components/JobInstruction"
import Training from "./components/Training"
import AssistantOverview from "./components/AssistantOverview"
import CompletedTask from "./components/CompletedTask"
import WorkSchedule from "./components/WorkSchedule"
import WorkPlan from "./components/WorkPlan"
import ImproveJob from "./components/ImproveJob"
import Register from "./components/Register"
import JobHistory from "./components/JobHistory"
import PIMConfig from "./components/PIMConfig"
import "./styles/style.css"
import "./i18n"

const userEmail = window.sessionStorage.getItem("email")
function App() {
  return (
    <div>
      <BrowserRouter>
        {userEmail ? <SideBar /> : ""}
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/signup" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/overview" element={<Dashboard />} />
          <Route exact path="/tools" element={<Tools />} />
          <Route exact path="/tools/pim" element={<PIMConfig />} />

          <Route exact path="/add-organization" element={<AddOrganization />} />
          <Route exact path="/organizations" element={<Organizations />} />
          <Route exact path="/:assistantID/job-instruction" element={<JobInstruction />} />
          <Route exact path="/:assistantID/job-description" element={<JobDescription />} />
          <Route exact path="/:assistantID/training" element={<Training />} />
          <Route exact path="/:assistantID/overview" element={<AssistantOverview />} />
          <Route exact path="/:assistantID/improve" element={<ImproveJob />} />
          <Route exact path="/:assistantID/work-schedule" element={<WorkSchedule />} />
          <Route exact path="/:assistantID/work-plan" element={<WorkPlan />} />
          <Route exact path="/:assistantID/history" element={<JobHistory />} />
          <Route exact path="/:assistantID/tasks" element={<CompletedTask />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
