import { useEffect, useState } from "react"
import UserService from "../services/UserService"
import AssistantService from "../services/AssistantService"
import TopBar from "./Topbar"
import { useTranslation } from "react-i18next"

function AddOrganization() {
	const [assistantList, setAssistantList] = useState([])
	const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
	const [orgFields, setOrgFields] = useState({
		organization_name: "",
		organization_description: "",
	})

	const [formState, setFormState] = useState({})
	const handleChange = (event) => {
		if (event.keyCode === "13") {
			handleSubmit(event)
		}
		setOrgFields({
			...orgFields,
			[event.target.name]: event.target.value,
		})
	}
	const { t } = useTranslation()
	useEffect(() => {
		AssistantService.assistantsList(organizationID).then((assistants) => {
			setAssistantList(assistants.data)
		})
	}, [organizationID])
	const handleSubmit = (event) => {
		event.preventDefault()
		UserService.addOrganization(orgFields, formState)
	}
	const handleChangee = (id, field, value) => {
		setFormState((prevState) => ({
			...prevState,
			[id]: {
				...prevState[id],
				[field]: value,
			},
		}))
	}

	return (
		<div>
			<div className="container-fluid">
				<div className="row">
					<main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
						<div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
							<button
								className="navbar-toggler bg-light position-absolute d-md-none collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#sidebarMenu"
								aria-controls="sidebarMenu"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<i className="bi bi-list"></i>
							</button>
						</div>
						<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
							<div className="col-md-3 col-lg-7 col-12 px-4 ">
								<h1 className="h1">{t("GENERAL.ORGANIZATIONS")}</h1>
								<div className="breadcrumb">{t("GENERAL.ORGANIZATIONS")}</div>
							</div>
							<div className="col-md-3 col-lg-3 p-4 ">&nbsp;</div>
							<TopBar />
						</div>
						<div className="container-fluid mt-4">
							<div className="row">
								<form action="post" onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-12">
											<h6>{t("ORGANIZATIONS.NAME")}</h6>
										</div>
										<div className="col-12 col-lg-8">
											<input
												type="text"
												name="organization_name"
												className="form-control"
												placeholder=""
												aria-label=""
												aria-describedby=""
												value={orgFields.organization_name}
												onChange={handleChange}
											/>
										</div>
										<div className="col-12 col-lg-8  pt-4">
											<h6>{t("GENERAL.DESCRIPTION")}</h6>
										</div>
										<div className="col-12 col-lg-8">
											<textarea
												name="organization_description"
												style={{ resize: "none" }}
												className="form-control"
												placeholder=""
												aria-label=""
												aria-describedby=""
												value={orgFields.organization_description}
												onChange={handleChange}
											></textarea>
										</div>
										<div className="col-12 col-lg-8  pt-4">
											<h6>{t("ORGANIZATIONS.ASSIGN_ASSISTANTS")}</h6>
										</div>
										<div className="col-12 col-lg-8">
											{assistantList
												? assistantList.map((value) => {
														return (
															<>
																<div className="row pt-3">
																	<div className="col-1">
																		<input
																			type="checkbox"
																			checked={formState[value.id]?.checked || false}
																			onChange={(e) => handleChangee(value.id, "checked", e.target.checked)}
																			className="checkbox"
																		/>
																	</div>
																	<div className="col-4">
																		{t("SIDEBAR.SEO_ASSISTANT")} ({t(value.description_slug)})
																	</div>
																	<div className="col-2">
																		{formState[value.id]?.checked && (
																			<input
																				type="number"
																				value={formState[value.id]?.value || ""}
																				onChange={(e) => handleChangee(value.id, "value", e.target.value)}
																				className="form-control"
																				min="0"
																			/>
																		)}
																	</div>
																</div>
															</>
														)
													})
												: ""}
										</div>
										<div className="col-12 col-lg-8  pt-4">
											<button className="btn btn-md fm-btn w-auto float-left me-md-2" type="submit">
												{t("GENERAL.SAVE_AND_AUTHENTICATE")}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</main>
				</div>
			</div>
		</div>
	)
}
export default AddOrganization
