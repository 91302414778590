import { NavLink, Link, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import UserService from "../services/UserService"
import AssistantService from "../services/AssistantService"
import Logo from "../images/Logo-overview.png"
import { useTranslation } from "react-i18next"
function Sidebar() {
  const location = useLocation()
  const { t } = useTranslation()
  const [user, setUser] = useState("")
  const [userRole, setUserRole] = useState("")
  const [organizationList, setOrganizationList] = useState()
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [assistantList, setAssistantList] = useState([])
  const switchOrganization = (event) => {
    const orgID = event.target.value
    const selectedOrgName = event.target.selectedOptions[0].text
    window.sessionStorage.setItem("organization_id", orgID)
    window.sessionStorage.setItem("organization_name", selectedOrgName)
    window.location.href = "/overview"
  }
  useEffect(() => {
    const accessToken = window.sessionStorage.getItem("email")
    setUserRole(window.sessionStorage.getItem("userrole"))

    UserService.getOrganizationList().then((response) => {
      setOrganizationList(response.data.organizations)
    })

    UserService.getUserFromToken(accessToken).then((user) => {
      setUser(user)
    })

    AssistantService.assistantsList(organizationID).then((assistants) => {
      setAssistantList(assistants.data)
    })
  }, [organizationID])
  useEffect(() => {
    const sidebar = document.getElementById("sidebarMenu")
    if (sidebar) {
      if (sidebar.classList.contains("show")) {
        sidebar.classList.remove("show")
      }
    }
  }, [location.pathname])
  const [expandedIndex, setExpandedIndex] = useState(0)
  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index)
    window.sessionStorage.setItem("currentAssistantId", assistantList[index].id)
  }

  return (
    <>
      <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar collapse fm-sidemenu-color">
        <div className="col-md-12 col-lg-12 d-md-block fm-sidebar-logo" align="center">
          <div className="row align-middle table-responsive" align="center">
            <div className="col-12">
              <img src={Logo} alt={Logo} className="img-fluid" />
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12 d-md-block p-2 fm-sidebar-user" align="center">
          <div className="row align-middle table-responsive" align="center">
            <div className="col-12">
              <table className="table table-sm align-middle" border="0">
                <tr className="align-middle">
                  <td width="40">&nbsp;</td>
                  <td className="align-middle">
                    <b>{user.name}</b>
                  </td>
                  <td>&nbsp;</td>
                  <td className="align-middle" align="right">
                    {(userRole === "1" || userRole === "2") && (
                      <select className="organizationSwitch" onChange={switchOrganization}>
                        {organizationList &&
                          organizationList.map((value) => {
                            return (
                              <>
                                <option value={value.id} selected={organizationID === value.id ? "selected" : ""}>
                                  {value.name}
                                </option>
                              </>
                            )
                          })}
                      </select>
                    )}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div className="position-sticky pt-3">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link className="nav-link" to={"/overview"}>
                <div className="menu-icon">
                  <i className="bi bi-speedometer2"></i>
                </div>
                <div className="menu-name">
                  <b>{t("GENERAL.COMPANY_INFO")}</b>
                </div>
              </Link>
            </li>
            {userRole === "1" && (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to={"/tools"}>
                    <div className="menu-icon">
                      <i className="bi bi-lock"></i>
                    </div>
                    <div className="menu-name">
                      <b>{t("GENERAL.TOOLS")}</b>
                    </div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/organizations"}>
                    <div className="menu-icon">
                      <i className="bi bi-lock"></i>
                    </div>
                    <div className="menu-name">
                      <b>{t("GENERAL.ORGANIZATIONS")}</b>
                    </div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/users"}>
                    <div className="menu-icon">
                      <i className="bi bi-people"></i>
                    </div>
                    <div className="menu-name">
                      <b>{t("GENERAL.USERS")}</b>
                    </div>
                  </Link>
                </li>
              </>
            )}

            {assistantList &&
              assistantList.map((val, index) => (
                <li key={index} className={`nav-item ${expandedIndex === index ? "expanded" : ""}`}>
                  <div className="nav-link" onClick={() => handleToggle(index)}>
                    <div className="assistantMenu">
                      <div className="toggleIcon">
                        {expandedIndex === index ? (
                          <i className="bi bi-chevron-up"></i>
                        ) : (
                          <i className="bi bi-chevron-down"></i>
                        )}
                      </div>
                      <div className="assistantIcon">
                        <i className="bi bi-search"></i>
                      </div>
                      <div className="assistantNameDesc">
                        <b>{t("SIDEBAR.SEO_ASSISTANT")}</b>
                        <br />
                        <span className="assistantDescription">
                          {val.customName ? val.customName : t("SIDEBAR." + val.descriptionSlug)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="nav-item-sub-item">
                    {userRole === "1" ? (
                      <li className="nav-item">
                        <NavLink className="nav-link" to={`/${val.id}/job-instruction`}>
                          <i className="bi bi-lock"></i>
                          {t("JOBS.INSTRUCTIONS")}
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="nav-item">
                      <NavLink className="nav-link" to={`/${val.id}/overview`}>
                        <i className="bi bi-speedometer2"></i>
                        {t("SIDEBAR.OVERVIEW")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to={`/${val.id}/job-description`}>
                        <i className="bi bi-card-checklist"></i>
                        {t("JOBS.JOB_DESCRIPTION")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to={`/${val.id}/training`}>
                        <i className="bi bi-clipboard-data"></i>
                        {t("TRAINING.TRAINING")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to={`/${val.id}/work-schedule`}>
                        <i className="bi bi-lightbulb"></i>
                        {t("WORK_SCHEDULE.SCHEDULE")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to={`/${val.id}/tasks`}>
                        <i className="bi bi-compass"></i>
                        {t("TASKS.COMPLETED.COMPLETED_TASKS")}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </nav>
    </>
  )
}
export default Sidebar
