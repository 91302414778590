import { useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import PromptService from "../services/PromptService"
import TopBar from "./Topbar"
import CrossCircle from "../images/cross-circle.png"
import EditIcon from "../images/edit.png"
import Unlock from "../images/unlock.png"
import Lock from "../images/lock.png"
import { useTranslation } from "react-i18next"
import { useParsedAssistantIdFromParams } from "../utils/utils"

function JobInstruction() {
  const { assistantID } = useParsedAssistantIdFromParams()
  const [trigger, setTrigger] = useState(false)
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [editSystemQuestionKey, setEditSystemQuestionKey] = useState("")
  const [editingSystemQuestion, setEditSystemQuestion] = useState(false)
  const [editUserQuestionKey, setEditUserQuestionKey] = useState("")
  const [editingUserQuestion, setEditUserQuestion] = useState(false)
  const [editingUserQuestionColumn, setEditUserQuestionColumn] = useState(false)
  let navigate = useNavigate()
  const [systemDefined, setSystemDefined] = useState()
  const [submitUserOneMsg, setSubmitUserOneMsg] = useState()
  const [submitSystemMsg, setSubmitSystemMsg] = useState()
  const [questionsList, setQuestionsList] = useState()

  const [newSystemDefinedQA, setNewSystemDefinedQA] = useState({
    q: "",
    a: "",
  })
  const [newUserDefinedQA, setNewUserDefinedQA] = useState({
    q: "",
    a: [""],
    help: "",
  })
  const { t } = useTranslation()

  useEffect(() => {
    const userRole = window.sessionStorage.getItem("userrole")
    if (userRole !== "1") {
      navigate("/overview")
    }
    PromptService.getAssistantQuestions(assistantID).then((response) => {
      setQuestionsList(response.questions)

      PromptService.getPromptByType(assistantID, "systemdefined", 0).then((response) => {
        const systemDefinedJson = JSON.parse(response.prompt.prompt)
        setSystemDefined(systemDefinedJson)
      })
      PromptService.getPromptByType(assistantID, "userdefined", organizationID)
      PromptService.getPromptByType(assistantID, "questionone", organizationID)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, assistantID])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (editingSystemQuestion) {
      if (newSystemDefinedQA.q) {
        setSubmitSystemMsg("")
        PromptService.editSystemDefinedPrompt(
          organizationID,
          assistantID,
          newSystemDefinedQA,
          editSystemQuestionKey
        ).then(() => {
          setTrigger((prev) => !prev)
          setNewSystemDefinedQA({
            q: "",
            a: "",
          })
          setEditSystemQuestion(false)
          setEditSystemQuestionKey("")
        })
      } else {
        setSubmitSystemMsg(t("JOBS.SYSTEM_Q_VALIDATION"))
      }
    } else {
      if (newSystemDefinedQA.q) {
        PromptService.addSystemDefinedPrompt(organizationID, assistantID, newSystemDefinedQA).then(() => {
          setTrigger((prev) => !prev)
          setNewSystemDefinedQA({
            q: "",
            a: "",
          })
        })
      } else {
        setSubmitSystemMsg(t("JOBS.SYSTEM_Q_VALIDATION"))
      }
    }
  }
  const handleSubmitUserOne = (event) => {
    event.preventDefault()
    if (editingUserQuestion === true) {
      if (newUserDefinedQA.q) {
        setSubmitUserOneMsg("")
        PromptService.editAssistantQuestion(
          assistantID,
          newUserDefinedQA.q,
          newUserDefinedQA.help,
          editUserQuestionKey
        ).then((response) => {
          setQuestionsList(response.questions)
          setTrigger((prev) => !prev)
          setNewUserDefinedQA({
            q: "",
            help: "",
          })
          setEditUserQuestion(false)
          setEditUserQuestionKey("")
        })
      } else {
        setSubmitUserOneMsg(t("JOBS.USER_ONE_Q_VALIDATION"))
      }
    } else {
      if (newUserDefinedQA.q) {
        setSubmitUserOneMsg("")
        PromptService.addAssistantQuestion(
          assistantID,
          newUserDefinedQA.q,
          newUserDefinedQA.help,
          editingUserQuestionColumn
        ).then((response) => {
          setQuestionsList(response.questions)
          setTrigger((prev) => !prev)
          setNewUserDefinedQA({
            q: "",
            help: "",
          })
          setEditUserQuestion(false)
        })
      } else {
        setSubmitUserOneMsg(t("JOBS.USER_ONE_Q_VALIDATION"))
      }
    }
  }
  const deleteSystemQuestion = (questionKey) => {
    var confirmed = window.confirm(t("CONFIRM_WINDOW.DELETE_ITEM"))
    if (confirmed) {
      PromptService.deleteSystemDefinedPrompt(assistantID, questionKey).then(() => {
        setTrigger((prev) => !prev)
      })
    }
  }

  const deleteAssistantQuestion = (questionID) => {
    var confirmed = window.confirm(t("CONFIRM_WINDOW.DELETE_ITEM"))
    if (confirmed) {
      PromptService.deleteAssistantQuestion(questionID).then(() => {
        setTrigger((prev) => !prev)
      })
    }
  }

  const editAssistantQuestion = (values, help, questionID, column) => {
    setEditUserQuestionKey(questionID)
    setEditUserQuestion(true)
    setEditUserQuestionColumn(column)
    setNewUserDefinedQA({
      q: values,
      help: help,
    })
  }
  const editSystemQuestion = (values, questionKey) => {
    setEditSystemQuestionKey(questionKey)
    setEditSystemQuestion(true)
    setNewSystemDefinedQA({
      q: values.q,
      a: values.a,
    })
  }
  const handleChange = (event) => {
    if (event.keyCode === "13") {
      handleSubmit(event)
    }
    setNewSystemDefinedQA({
      ...newSystemDefinedQA,
      [event.target.name]: event.target.value,
    })
  }
  const handleUserOneColumn = (event) => {
    setEditUserQuestionColumn(event.target.value)
  }
  const handleUserOneChange = (event) => {
    if (event.keyCode === "13") {
      handleSubmit(event)
    }
    setNewUserDefinedQA({
      ...newUserDefinedQA,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
              <div className="col-md-3 col-lg-7  col-12 px-4 ">
                <h1 className="h1">{t("JOBS.INSTRUCTIONS")}</h1>
              </div>
              <div className="col-md-3 col-lg-3 p-4 ">&nbsp;</div>
              <TopBar />
            </div>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0  mb-3">
              <div className="col-md-3 col-lg-12 px-4 ">
                <p>{t("JOBS.INSTRUCTION_PAGE_SUBLINE")}</p>
              </div>
            </div>

            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12 fm-content-box p-4">
                  <div className="col-12">
                    <h3>{t("JOBS.ADD_ASSISTANT_INSTR")}</h3>
                  </div>
                  <div className="col-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                          <input
                            type="text"
                            name="q"
                            value={newSystemDefinedQA.q}
                            onChange={handleChange}
                            className="form-control"
                            placeholder=""
                            aria-label=""
                            aria-describedby=""
                          />
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9  col-xs-12">
                          <div className="input-group mb-3">
                            <textarea
                              name="a"
                              value={newSystemDefinedQA.a}
                              onChange={handleChange}
                              className="form-control"
                            ></textarea>
                            <button className="btn fm-btn" type="submit">
                              {editingSystemQuestion ? t("GENERAL.EDIT") : t("GENERAL.ADD")}
                            </button>
                          </div>
                        </div>
                        <div className="error">{submitSystemMsg ? submitSystemMsg : ""}</div>
                      </div>
                    </form>
                  </div>

                  {systemDefined &&
                    systemDefined
                      .slice()
                      .reverse()
                      .map((value, key) => {
                        const displayIndex = systemDefined.length - 1 - key
                        return (
                          <>
                            <div className="col-12 mt-3 align-middle">
                              <div className="col-lg-12 fm-card-tool p-2 align-middle d-flex flex-wrap flex-md-nowrap align-items-center">
                                <div className="question_lock">
                                  <img src={Lock} alt="lock" />
                                </div>
                                <div className="question_question">
                                  <h1>{value.q}</h1>
                                  <p>{value.a}</p>
                                </div>
                                <div className="col-lg-1 col-xs-12 ms-auto" align="right">
                                  <img
                                    src={EditIcon}
                                    onClick={() => editSystemQuestion(value, displayIndex)}
                                    className="close-btn"
                                    title={t("GENERAL.EDIT")}
                                    alt={t("GENERAL.EDIT")}
                                  />
                                  <img
                                    src={CrossCircle}
                                    onClick={() => deleteSystemQuestion(displayIndex)}
                                    className="close-btn"
                                    title={t("GENERAL.DELETE")}
                                    alt={t("GENERAL.DELETE")}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                </div>
              </div>

              <div className="row mt-5 mb-5">
                <div className="col-12 fm-content-box p-4">
                  <div className="col-12">
                    <h3>{t("JOBS.ADD_ASSISTANT_INSTR")}</h3>
                  </div>
                  <div className="col-12">
                    <form onSubmit={handleSubmitUserOne}>
                      <div className="row">
                        <div className="col-12 col-lg-2">
                          <div className="input-group mb-3">
                            <select onChange={handleUserOneColumn} className="form-control">
                              <option>Velg kolonne</option>
                              <option
                                value="onecolumn"
                                selected={editingUserQuestionColumn === "onecolumn" && "selected"}
                              >
                                Én kolonne
                              </option>
                              <option
                                value="twocolumn"
                                selected={editingUserQuestionColumn === "twocolumn" && "selected"}
                              >
                                To kolonner
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-lg-10">
                          <div class="custom-input-group">
                            <div className="input-group mb-3">
                              <div className="col-12 col-lg-5">
                                <textarea
                                  name="q"
                                  onChange={handleUserOneChange}
                                  value={newUserDefinedQA.q}
                                  className="form-control"
                                ></textarea>
                              </div>
                              <div className="col-12 col-lg-6 px-lg-2 mt-3 mt-md-0">
                                <textarea
                                  name="help"
                                  onChange={handleUserOneChange}
                                  value={newUserDefinedQA.help}
                                  className="form-control"
                                ></textarea>
                              </div>
                              <div className="col-12 col-lg-1 mt-3  mt-md-0" align="right">
                                <button className="btn fm-btn" type="submit">
                                  {editingUserQuestion ? t("GENERAL.EDIT") : t("GENERAL.ADD")}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="error">{submitUserOneMsg && submitUserOneMsg}</div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="col-12 mt-3 align-middle">
                    {questionsList &&
                      questionsList.map((value) => {
                        return (
                          <>
                            <div className="col-12 mt-3 align-middle">
                              <div className="col-lg-12 fm-card-tool p-2 align-middle d-flex flex-wrap flex-md-nowrap align-items-center">
                                <div className="question_lock">
                                  <img src={Unlock} alt="unlock" />
                                </div>
                                <div className="question_question">
                                  <h1>{value.question}</h1>
                                  {value.help && (
                                    <p>
                                      <i class="bi bi-info-circle"></i> {value.help}
                                    </p>
                                  )}
                                </div>

                                <div className="col-lg-1 col-xs-12 ms-auto" align="right">
                                  <img
                                    src={EditIcon}
                                    onClick={() =>
                                      editAssistantQuestion(value.question, value.help, value.id, value.type)
                                    }
                                    className="close-btn"
                                    title={t("GENERAL.EDIT")}
                                    alt={t("GENERAL.EDIT")}
                                  />
                                  <img
                                    src={CrossCircle}
                                    onClick={() => deleteAssistantQuestion(value.id)}
                                    className="close-btn"
                                    title={t("GENERAL.DELETE")}
                                    alt={t("GENERAL.DELETE")}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default JobInstruction
