import { NavLink } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ProductService from "../services/ProductService"
import AssistantService from "../services/AssistantService"
import CreditBar from "./CreditBar"
import AttributeNamesss from "./AttributeNames"
import TopBar from "./Topbar"
import { useParsedAssistantIdFromParams } from "../utils/utils"
import PIMServicesInstance from "../services/PIMServices"

function AssistantOverview() {
  const { assistantID } = useParsedAssistantIdFromParams()
  const [totalProducts, setTotalProducts] = useState()
  const [totalOptimized, setTotalOptimized] = useState()
  const [totalReadyForExport, setTotalReadyForExport] = useState()
  const [totalExported, setTotalExported] = useState()
  const [workPlanList, setWorkPlanList] = useState([])
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [totalCredit, setTotalCredit] = useState()
  const [creditUsed, setCreditUsed] = useState()
  const [showdisableWorkScheduleMsg, setShowdisableWorkScheduleMsg] = useState(true)
  const { t } = useTranslation()
  useEffect(() => {
    ProductService.productStats(assistantID, organizationID).then((response) => {
      setTotalProducts(response.data.total_products)
      setTotalOptimized(response.data.total_optimized)
      setTotalReadyForExport(response.data.total_ready_for_export)
      setTotalExported(response.data.total_exported)

      AssistantService.getWorkPlanList(assistantID, organizationID).then((response) => {
        setWorkPlanList(response.data.workPlans)
      })
    }, [assistantID, organizationID])

    AssistantService.getConsumedCredits(assistantID, organizationID).then((assistresponse) => {
      setTotalCredit(assistresponse.credits)
      setCreditUsed(assistresponse.consumed)
    })

    PIMServicesInstance.getPIMConfig(organizationID)
      .then((response) => {
        setShowdisableWorkScheduleMsg(false)
      })
      .catch((error) => {
        setShowdisableWorkScheduleMsg(true)
      })
  }, [organizationID, assistantID])

  const StopResumeWorkPlan = (workPlanID, stopresume) => {
    var confirmed
    if (stopresume === "stopped") {
      confirmed = window.confirm(t("CONFIRM_WINDOW.STOP_WORKPLAN"))
    } else if (stopresume === "processing") {
      confirmed = window.confirm(t("CONFIRM_WINDOW.RESUME_WORKPLAN"))
    } else if (stopresume === "pending") {
      confirmed = window.confirm(t("CONFIRM_WINDOW.RESTART_WORKPLAN"))
    }
    if (confirmed) {
      const updatedWorkPlanList = workPlanList.map((workPlan) =>
        workPlan.id === workPlanID ? { ...workPlan, status: stopresume } : workPlan
      )
      setWorkPlanList(updatedWorkPlanList)
      AssistantService.stopResumeWorkPlan(workPlanID, stopresume)
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }
    return new Intl.DateTimeFormat("en-GB", options).format(date).replace(",", " at")
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">{t("ASSISTANT_OVERVIEW.OVERVIEW")}</h1>
                <div className="breadcrumb">{t("ASSISTANT_OVERVIEW.OVERVIEW")}</div>
              </div>
              <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
              <TopBar />
            </div>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
              <div className="col-md-3 col-lg-12 px-4 ">
                <p>{t("ASSISTANT_OVERVIEW.PAGE_SUBLINE")}</p>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                  <div className="fm-top-box ">
                    <div className="assistantOverviewText">
                      <h1>{totalProducts}</h1>
                      {t("ASSISTANT_OVERVIEW.READY")}
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                  <div className="fm-top-box ">
                    <div className="assistantOverviewText">
                      <h1>{totalOptimized}</h1>
                      {t("ASSISTANT_OVERVIEW.OPTIMIZED")}
                    </div>
                    <br />
                    <br />
                    <NavLink to={"/" + assistantID + "/tasks"}>
                      <button className="btn btn-lg fm-btn me-md-2" type="button">
                        {t("ASSISTANT_OVERVIEW.SEE_OPTIMIZED")}
                      </button>{" "}
                    </NavLink>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                  <div className="fm-top-box ">
                    <div className="assistantOverviewText">
                      <h1>{totalProducts - totalOptimized}</h1>
                      {t("ASSISTANT_OVERVIEW.NOT_READY")}
                    </div>
                    <br />
                    <br />
                    <NavLink to={"/" + assistantID + "/tasks"}>
                      <button className="btn btn-lg fm-btn me-md-2" type="button">
                        {t("ASSISTANT_OVERVIEW.SEE_NOT_OPTIMIZED")}
                      </button>{" "}
                    </NavLink>
                  </div>
                </div>
                {totalReadyForExport > 0 ? (
                  <>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                      <div className="fm-top-box ">
                        <div className="assistantOverviewText">
                          <h1>{totalReadyForExport}</h1>
                          {t("TASKS.READY_FOR_EXPORT")}
                        </div>
                        <br />
                        <br />
                        <NavLink to={"/" + assistantID + "/tasks"}>
                          <button className="btn btn-lg fm-btn me-md-2" type="button">
                            {t("TASKS.READY_FOR_EXPORT")}
                          </button>{" "}
                        </NavLink>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {totalExported > 0 ? (
                  <>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1 p-3" align="center">
                      <div className="fm-top-box ">
                        <div className="assistantOverviewText">
                          <h1>{totalExported}</h1>
                          {t("TASKS.EXPORTED")}
                        </div>
                        <br />
                        <br />
                        <NavLink to={"/" + assistantID + "/tasks"}>
                          <button className="btn btn-lg fm-btn me-md-2" type="button">
                            {t("TASKS.EXPORTED")}
                          </button>{" "}
                        </NavLink>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {showdisableWorkScheduleMsg === true ? (
              <></>
            ) : (
              <>
                <div className="container-fluid  p-3">
                  <div className="row mt-4  p-2 ">
                    <div className="col-6 col-lg-2 pt-2">
                      <h4 className="h4">{t("ASSISTANT_OVERVIEW.ACTIVE_WORK_PLANS")}</h4>
                    </div>
                    <div className="col-6 col-lg-3">
                      <NavLink to={"/" + assistantID + "/work-schedule"}>
                        <button className="btn btn-lg fm-btn me-md-2" type="button">
                          {t("WORK_SCHEDULE.SCHEDULE")}
                        </button>{" "}
                      </NavLink>
                    </div>
                  </div>
                  <div className="row   p-3">
                    {workPlanList ? (
                      <div className="col-12 fm-content-box">
                        <div className="col-12 table-responsive mt-4   fm-table ">
                          <table className="table table-hover align-top">
                            <tbody>
                              <tr>
                                <th scope="col">{t("WORK_SCHEDULE.SELECTION")}</th>
                                <th scope="col"> {t("WORK_SCHEDULE.TIME")}</th>
                                <th scope="col">{t("WORK_SCHEDULE.STATUS")}</th>
                                <th scope="col">{t("WORK_SCHEDULE.ACTIVITY")}</th>
                              </tr>
                              {workPlanList.map((value, key) => {
                                return (
                                  <>
                                    <tr className={"workplan-" + value.status} title={value.status}>
                                      <td width="30%">
                                        {value.type === "criteria" ? (
                                          <AttributeNamesss idlist={value.attribute_ids} />
                                        ) : (
                                          value.isbns.substr(0, 80) + "..."
                                        )}
                                      </td>
                                      <td width="25%">
                                        {value.status === "completed" ? (
                                          t("GENERAL.COMPLETED")
                                        ) : (
                                          <>
                                            {" "}
                                            {t("WORK_SCHEDULE.NEXT_RUN")}: {formatDate(value.cron_start_date)}{" "}
                                          </>
                                        )}
                                      </td>
                                      <td width="30%">
                                        <div className="creditProgressBarContainer">
                                          <div
                                            className="creditProgressBarGreen"
                                            style={{
                                              width:
                                                Math.round((100 * value.generated_products) / value.total_products) +
                                                "%",
                                            }}
                                          >
                                            &nbsp;
                                          </div>
                                        </div>
                                        <div className="creditProgressText">
                                          <b>{t("GENERAL.UPDATED")}:</b> {value.generated_products}{" "}
                                          {t("CREDIT_PROGRESS.OUT_OF")} {value.total_products}.{" "}
                                          <b>{t("CREDIT_PROGRESS.REMAINING")}:</b>{" "}
                                          {value.total_products - value.generated_products}
                                        </div>
                                      </td>
                                      <td width="15%">
                                        {value.status === "processing" || value.status === "pending" ? (
                                          <>
                                            <button
                                              onClick={() => StopResumeWorkPlan(value.id, "stopped")}
                                              className="btn btn-md fm-btn me-md-2"
                                            >
                                              {t("GENERAL.STOP")}
                                            </button>
                                          </>
                                        ) : value.status === "stopped" ? (
                                          <>
                                            <button
                                              onClick={() => StopResumeWorkPlan(value.id, "processing")}
                                              className="btn btn-md fm-btn me-md-2"
                                            >
                                              {t("GENERAL.RESUME")}
                                            </button>
                                          </>
                                        ) : (
                                          <> {t("ASSISTANT_OVERVIEW.NOT_ACTIVE_WORK_PLANS")} </>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="col-10 table-responsive mt-4">{t("WORK_SCHEDULE.NO_WORK_PLANS")}</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </main>
        </div>
      </div>
    </div>
  )
}
export default AssistantOverview
