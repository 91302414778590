import { NavLink } from "react-router-dom"
import React, { useEffect, useState } from "react"
import AssistantService from "../services/AssistantService"
import AttributeNames from "./AttributeNames"
import CreditBar from "./CreditBar"
import TopBar from "./Topbar"
import { useTranslation } from "react-i18next"
import "react-datepicker/dist/react-datepicker.css"
import CloseIcon from "../images/close.png"
import { useParsedAssistantIdFromParams } from "../utils/utils"
import PIMServicesInstance from "../services/PIMServices"

function WorkSchedule() {
  const { assistantID } = useParsedAssistantIdFromParams()
  const [startAgainID, setStartAgainID] = useState(false)
  const [showStartAgainModal, setShowStartAgainModal] = useState(false)
  const [workPlanList, setWorkPlanList] = useState([])
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [statsSEOGenerated, setStatsSEOGenerated] = useState("")
  const [statsSEOTotal, setStatsSEOTotal] = useState("")
  const [totalCredit, setTotalCredit] = useState()
  const [creditUsed, setCreditUsed] = useState()
  const [showdisableWorkScheduleMsg, setShowdisableWorkScheduleMsg] = useState(true)
  const { t } = useTranslation()
  const formatDate = (dateString) => {
    const date = new Date(dateString)

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }

    return new Intl.DateTimeFormat("en-GB", options).format(date).replace(",", " at")
  }
  const startAgainWorkPlan = (workPlanID) => {
    AssistantService.startAgainWorkPlanStats(workPlanID).then((response) => {
      setStatsSEOGenerated(response.data.generated)
      setStatsSEOTotal(response.data.total)
      setShowStartAgainModal(true)
      setStartAgainID(workPlanID)
    })
  }
  const StopResumeWorkPlan = (workPlanID, stopresume) => {
    var confirmed
    if (stopresume === "stopped") {
      confirmed = window.confirm(t("CONFIRM_WINDOW.STOP_WORKPLAN"))
    } else if (stopresume === "processing") {
      confirmed = window.confirm(t("CONFIRM_WINDOW.RESUME_WORKPLAN"))
    } else if (stopresume === "pending") {
      confirmed = window.confirm(t("CONFIRM_WINDOW.RESTART_WORKPLAN"))
    }
    if (confirmed) {
      const updatedWorkPlanList = workPlanList.map((workPlan) =>
        workPlan.id === workPlanID ? { ...workPlan, status: stopresume } : workPlan
      )
      setWorkPlanList(updatedWorkPlanList)
      AssistantService.StopResumeWorkPlan(workPlanID, stopresume)
    }
  }
  useEffect(() => {
    AssistantService.getWorkPlanList(assistantID, organizationID).then((response) => {
      setWorkPlanList(response.data.workPlans)
    })

    AssistantService.getConsumedCredits(assistantID, organizationID).then((assistresponse) => {
      setTotalCredit(assistresponse.credits)
      setCreditUsed(assistresponse.consumed)
    })

    PIMServicesInstance.getPIMConfig(organizationID)
      .then(() => {
        setShowdisableWorkScheduleMsg(false)
      })
      .catch(() => {
        setShowdisableWorkScheduleMsg(true)
      })
  }, [assistantID, organizationID])
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">{t("WORK_SCHEDULE.SCHEDULE")}</h1>
                <div className="breadcrumb">{t("WORK_SCHEDULE.SCHEDULE")}</div>
              </div>
              <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
              <TopBar />
            </div>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
              <div className="col-md-3 col-lg-8 px-4 ">
                <p>{t("WORK_SCHEDULE.PAGE_SUBLINE")}</p>
              </div>
            </div>
            {showdisableWorkScheduleMsg === true ? (
              <>
                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12">
                      {t("WORK_SCHEDULE.CONFIGURE_TOOLS")}{" "}
                      <NavLink className="links" to={"/tools"}>
                        {t("WORK_SCHEDULE.CLICK_HERE")}
                      </NavLink>{" "}
                      {t("WORK_SCHEDULE.TO_ADD_CONFIG")}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="">
                      <h4 className="h4">{t("WORK_SCHEDULE.WORK_PLAN_LIST")}</h4>
                    </div>
                  </div>
                </div>

                <div className="container-fluid">
                  <div className="row ">
                    {workPlanList ? (
                      <div className="col-12 fm-content-box">
                        <div className="col-12 table-responsive mt-4  fm-table ">
                          <table className="table table-hover align-top">
                            <tbody>
                              <tr>
                                <th scope="col">{t("WORK_SCHEDULE.SELECTION")}</th>
                                <th scope="col"> {t("WORK_SCHEDULE.TIME")}</th>
                                <th scope="col">{t("WORK_SCHEDULE.STATUS")}</th>
                                <th scope="col">{t("WORK_SCHEDULE.ACTIVITY")}</th>
                              </tr>

                              {workPlanList.map((value) => {
                                return (
                                  <>
                                    <tr className={"workplan-" + value.status} title={value.status}>
                                      <td width="30%">
                                        {value.type === "criteria" ? (
                                          <AttributeNames idlist={value.attribute_ids} />
                                        ) : (
                                          value.isbns.substr(0, 80) + "..."
                                        )}
                                      </td>
                                      <td width="25%">
                                        {value.status === "completed" ? (
                                          t("GENERAL.COMPLETED")
                                        ) : (
                                          <>
                                            {" "}
                                            {t("WORK_SCHEDULE.NEXT_RUN")}: {formatDate(value.cron_start_date)}{" "}
                                          </>
                                        )}
                                      </td>
                                      <td width="30%">
                                        <div className="creditProgressBarContainer">
                                          <div
                                            className="creditProgressBarGreen"
                                            style={{
                                              width:
                                                Math.round((100 * value.generated_products) / value.total_products) +
                                                "%",
                                            }}
                                          >
                                            &nbsp;
                                          </div>
                                        </div>
                                        <div className="creditProgressText">
                                          <b>{t("GENERAL.UPDATED")}:</b> {value.generated_products}{" "}
                                          {t("CREDIT_PROGRESS.OUT_OF")} {value.total_products}.{" "}
                                          <b>{t("CREDIT_PROGRESS.REMAINING")}:</b>{" "}
                                          {value.total_products - value.generated_products}
                                        </div>
                                      </td>
                                      <td width="15%">
                                        {value.status === "processing" || value.status === "pending" ? (
                                          <>
                                            <button
                                              onClick={() => StopResumeWorkPlan(value.id, "stopped")}
                                              className="btn btn-md fm-btn me-md-2"
                                            >
                                              {t("GENERAL.STOP")}
                                            </button>
                                          </>
                                        ) : value.status === "stopped" ? (
                                          <>
                                            <button
                                              onClick={() => StopResumeWorkPlan(value.id, "processing")}
                                              className="btn btn-md fm-btn me-md-2"
                                            >
                                              {t("GENERAL.RESUME")}
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              onClick={() => {
                                                startAgainWorkPlan(value.id)
                                              }}
                                              className="btn btn-md fm-btn me-md-2"
                                            >
                                              {t("WORK_SCHEDULE.START_AGAIN")}
                                            </button>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="col-10 table-responsive mt-4">{t("WORK_SCHEDULE.NO_WORK_PLANS")}</div>
                    )}
                  </div>
                </div>

                <div className="container-fluid mt-4">
                  <div className="row">
                    <div className="col-12">
                      <NavLink to={"/" + assistantID + "/work-plan"}>
                        <button className="btn btn-md fm-btn me-md-2" type="button">
                          {t("WORK_SCHEDULE.SET_UP")}
                        </button>{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </>
            )}

            {showStartAgainModal && (
              <div className="modal display-block">
                <div className="modal-main">
                  <div
                    style={{
                      float: "right",
                      marginTop: "-15px",
                      marginRight: "-5px",
                    }}
                  >
                    <img
                      alt="close"
                      src={CloseIcon}
                      onClick={() => setShowStartAgainModal(false)}
                      className="close-btn"
                      width="12"
                    />
                  </div>
                  <div className="modal-contentt">
                    <div className="row m-0">
                      <div className="col-12">
                        <div>
                          It is {statsSEOTotal - statsSEOGenerated}{" "}
                          {statsSEOTotal - statsSEOGenerated > 1 ? "products" : "product"} that fit your criteria
                        </div>
                        <div className="pt-3">
                          <input className="checkbox" type="checkbox" />
                          &nbsp; Also include already optimized products ({statsSEOGenerated} optimized, total{" "}
                          {statsSEOTotal})
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-4">
                        <div className="pt-3">
                          <button
                            onClick={() => StopResumeWorkPlan(startAgainID, "pending")}
                            className="btn btn-md fm-btn me-md-2"
                          >
                            {t("WORK_SCHEDULE.START_AGAIN")}{" "}
                          </button>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="pt-3">
                          <button onClick={() => setShowStartAgainModal(false)} className="btn btn-md fm-btn me-md-2">
                            {t("GENERAL.CANCEL")}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  )
}
export default WorkSchedule
