import axios from 'axios'

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  withCredentials: true,
})

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

httpClient.interceptors.request.use(
  (config) => {
    const token = window.sessionStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

httpClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry && error.response.data.error === 'Invalid token') {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return httpClient(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise(function (resolve, reject) {
        httpClient
          .post(`${process.env.REACT_APP_API_SERVER_URL}refresh-token`)
          .then(({ data }) => {
            window.sessionStorage.setItem('token', data.access_token)
            httpClient.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token
            processQueue(null, data.access_token)
            resolve(httpClient(originalRequest))
          })
          .catch((err) => {
            processQueue(err, null)
            reject(err)
          })
          .then(() => {
            isRefreshing = false
          })
      })
    }
    return Promise.reject(error)
  }
)

export default httpClient
