import { useTranslation } from "react-i18next"

function CreditBar({ creditUsed, totalCredit }) {
  const { t } = useTranslation()
  const totalPrcent = Math.round((creditUsed * 100) / totalCredit)
  return (
    <>
      <div className="col-md-3 col-lg-3 p-4 ms-auto ">
        <div className="creditProgressHeading">
          <b>{t("CREDIT_PROGRESS.CREDITS")}:</b> {totalPrcent}% {t("CREDIT_PROGRESS.LOWERCASE_USED")}
        </div>
        <div className="creditProgressBarContainer">
          <div
            className={
              totalPrcent > 90
                ? "creditProgressBarMaroon"
                : totalPrcent > 75
                  ? "creditProgressBarRed"
                  : totalPrcent > 50
                    ? "creditProgressBarOrange"
                    : totalPrcent > 25
                      ? "creditProgressBarYellow"
                      : "creditProgressBarGreen"
            }
            style={{ width: (creditUsed * 100) / totalCredit + "%" }}
          >
            &nbsp;
          </div>
        </div>
        <div className="creditProgressText">
          <b>{t("CREDIT_PROGRESS.CAPITALIZED_USED")}:</b> {creditUsed} {t("CREDIT_PROGRESS.OUT_OF")} {totalCredit}.{" "}
          <b>{t("CREDIT_PROGRESS.REMAINING")}:</b> {totalCredit - creditUsed}
        </div>
      </div>
    </>
  )
}
export default CreditBar
