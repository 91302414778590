import { Link, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import PromptService from "../services/PromptService"
import AssistantService from "../services/AssistantService"
import CreditBar from "./CreditBar"
import RestoreIcon from "../images/restore.png"
import TopBar from "./Topbar"
import { useTranslation } from "react-i18next"
import { useParsedAssistantIdFromParams } from "../utils/utils"

function JobHistory() {
  const { assistantID } = useParsedAssistantIdFromParams()
  let navigate = useNavigate()
  const { t } = useTranslation()
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalProgressPages, setTotalProgressPages] = useState(1)
  const [totalCredit, setTotalCredit] = useState()
  const [creditUsed, setCreditUsed] = useState()
  const [groupData, setGroupData] = useState({})
  const [groupsList, setGroupsList] = useState()

  useEffect(() => {
    PromptService.promptHistory(assistantID, organizationID, pageNumber).then((response) => {
      setTotalProgressPages(response.total_pages)
      setGroupsList(response.groups)
      response.groups.forEach((gro) => {
        fetchGroupData(gro.group)
      })
    })

    AssistantService.getConsumedCredits(assistantID, organizationID).then((assistresponse) => {
      setTotalCredit(assistresponse.credits)
      setCreditUsed(assistresponse.consumed)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID, assistantID, pageNumber])

  const fetchGroupData = async (groupID) => {
    try {
      PromptService.fetchGroupData(assistantID, organizationID, groupID).then((response) => {
        setGroupData((prevData) => ({
          ...prevData,
          [groupID]: response.answers,
        }))
      })
    } catch (error) {}
  }

  const restoreJobDescription = (groupID) => {
    const confirmed = window.confirm(t("CONFIRM_WINDOW.RESTORE_JOB_DESCRIPTION"))
    if (confirmed) {
      PromptService.promptRestore(groupID, assistantID, organizationID).then(() => {
        navigate("/" + assistantID + "/job-description")
      })
    }
  }
  const handlePageClick = (page) => {
    setPageNumber(page)
  }

  const handleNextClick = () => {
    if (pageNumber < totalProgressPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const handlePreviousClick = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const handleFirstClick = () => {
    setPageNumber(1)
  }

  const handleLastClick = () => {
    setPageNumber(totalProgressPages)
  }
  const generatePageNumbers = () => {
    const pageNumbers = []
    const maxPagesToShow = 7
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2)

    let startPage = Math.max(1, pageNumber - halfMaxPagesToShow)
    let endPage = Math.min(totalProgressPages, pageNumber + halfMaxPagesToShow)

    if (pageNumber - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalProgressPages, endPage + (halfMaxPagesToShow - (pageNumber - 1)))
    }

    if (pageNumber + halfMaxPagesToShow > totalProgressPages) {
      startPage = Math.max(1, startPage - (pageNumber + halfMaxPagesToShow - totalProgressPages))
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }

    return pageNumbers
  }
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">{t("JOBS.JOB_HISTORY")}</h1>
                <div className="breadcrumb">
                  <Link to={"/" + assistantID + "/job-description"}>{t("JOBS.DESCRIPTION_FOR_ASSISTANT")}</Link>
                  &nbsp; &raquo; {t("JOBS.JOB_HISTORY")}
                </div>
              </div>
              <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
              <TopBar />
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
              <div className="col-md-3 col-lg-8 px-4 "></div>
            </div>

            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12 fm-content-box p-4">
                  <div className="col-12">
                    <h3>{t("JOBS.PREVIOUS_DESCRIPTIONS")}</h3>
                  </div>
                  <div className="col-12 table-responsive mt-4 fm-table">
                    <table className="table table-hover align-top">
                      <tbody>
                        <tr>
                          <th scope="col">SR.</th>
                          <th scope="col">{t("JOBS.JOB_DESCRIPTION")}</th>
                          <th scope="col" className="col-1">
                            {t("GENERAL.ACTION")}
                          </th>
                        </tr>
                        {groupsList &&
                          groupsList.map((group) => {
                            let printedQuestions = new Set()
                            return (
                              <>
                                <tr>
                                  <td>{group.serial_number}</td>
                                  <td>
                                    {groupData[group.group] &&
                                      groupData[group.group].map((value) => {
                                        var questionTitle
                                        if (!printedQuestions.has(value.question)) {
                                          questionTitle = (
                                            <>
                                              <br />
                                              <b>{value.question}</b>
                                              <br />
                                            </>
                                          )
                                          printedQuestions.add(value.question)
                                        }
                                        return (
                                          <>
                                            <b>{questionTitle}</b>
                                            {value.answer_heading && value.answer_heading + " - "} {value.answer}
                                            <br />
                                          </>
                                        )
                                      })}
                                  </td>
                                  <td>
                                    <img
                                      alt={group.group}
                                      onClick={() => restoreJobDescription(group.group)}
                                      title={t("JOBS.RESTORE")}
                                      className="close-btn"
                                      src={RestoreIcon}
                                    />
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                      <tr>
                        <td colspan="5">
                          <button className="paging" onClick={handleFirstClick} disabled={pageNumber === 1}>
                            {t("GENERAL.FIRST")}
                          </button>
                          <button className="paging" onClick={handlePreviousClick} disabled={pageNumber === 1}>
                            {t("GENERAL.PREVIOUS")}
                          </button>
                          {generatePageNumbers().map((page) => (
                            <button
                              className={pageNumber === page ? "currpaging" : "paging"}
                              key={page}
                              onClick={() => handlePageClick(page)}
                            >
                              {page}
                            </button>
                          ))}
                          <button
                            className="paging"
                            onClick={handleNextClick}
                            disabled={pageNumber === totalProgressPages}
                          >
                            {t("GENERAL.NEXT")}
                          </button>
                          <button
                            className="paging"
                            onClick={handleLastClick}
                            disabled={pageNumber === totalProgressPages}
                          >
                            {t("GENERAL.LAST")}
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default JobHistory
