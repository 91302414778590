import { useNavigate } from "react-router-dom"
import React, { useEffect } from "react"

function LoginCheck() {
  let navigate = useNavigate()
  const userEmail = window.sessionStorage.getItem("email")
  useEffect(() => {
    const userEmail = window.sessionStorage.getItem("email")
    if (userEmail) {
    } else {
      navigate("/")
    }
  }, [navigate, userEmail])

  return <div align="center"></div>
}
export default LoginCheck
