import { useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react"
import AuthService from "../services/AuthService"
import Logo from "../images/logo-login.png"
function Login() {
  const [loginMessage, setLoginMessage] = useState(null)
  let navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })

  const handleChange = (event) => {
    if (event.keyCode === "13") {
      handleSubmit(event)
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }
  useEffect(() => {
    document.body.classList.add("custom-background")
    return () => {
      document.body.classList.remove("custom-background")
    }
  }, [])
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoginMessage("")
    AuthService.login(formData.email, formData.password).then(
      (response) => {
        setLoginMessage(response.data.message)
        window.sessionStorage.setItem("email", formData.email)
        window.sessionStorage.setItem("user_id", response.data.user_id)
        window.sessionStorage.setItem("userrole", response.data.userrole)
        window.sessionStorage.setItem("organization_id", response.data.organization_id)
        window.sessionStorage.setItem("organization_name", response.data.organization_name)
        window.sessionStorage.setItem("token", response.data.token)
        window.location.href = "/overview"
      },
      (error) => {
        setLoginMessage(error.response.data.error)
      }
    )
  }
  useEffect(() => {
    const userEmail = window.sessionStorage.getItem("email")
    if (userEmail) {
      navigate("/overview")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col align-self-center" align="center">
            <br />
            <br />
            <img alt="logo" src={Logo} className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row p-4 fm-login-container">
          <div className="col-lg-5 h-100 align-self-top">
            <div className="col" align="center">
              <h1>Login to Account</h1>

              <p>Please enter your email and password to continue</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="form-control form-control-lg"
                  id="exampleInputPassword1"
                />
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                <label className="form-check-label" for="exampleCheck1">
                  Remember Password
                </label>
              </div>
              <div className="d-grid gap-2 col-8 mx-auto">
                <button type="submit" className="btn btn-primary btn-lg va-btn-color">
                  SIGN IN
                </button>
              </div>
              <div align="center">{loginMessage ? loginMessage : ""}</div>
            </form>
          </div>

          <div className="col-lg-2 h-100 align-self-top d-none d-lg-block" align="center">
            <div className="login-sap-line"></div>
          </div>

          <div
            className="col align-self-top d-sm-block d-xs-block d-lg-none d-xxl-none"
            align="center"
            style={{ padding: "20px 0px 20px 0px" }}
          >
            <div className="login-sap-line-mob"></div>
          </div>

          <div className="col-lg-5 h-100 align-self-top">
            <div className="col" align="center">
              <h1>Sign Up</h1>
              <p>Please enter your email and password to continue</p>
            </div>
            <form>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" />
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Repeat Password
                </label>
                <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" />
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                <label className="form-check-label" for="exampleCheck1">
                  Remember Password
                </label>
              </div>
              <div className="d-grid gap-2 col-8 mx-auto">
                <button type="submit" className="btn btn-primary btn-lg va-btn-color">
                  SIGN UP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login
