import { useState } from "react"
import LoginCheck from "./LoginCheck"
import { useTranslation } from "react-i18next"

function Topbar() {
  const { i18n } = useTranslation()
  const [currLang, setCurrLang] = useState("en")
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    lng === "en" ? setCurrLang("nor") : setCurrLang("en")
  }
  const { t } = useTranslation()
  const logoutUser = () => {
    window.sessionStorage.setItem("email", "")
    window.location.href = "/"
  }

  return (
    <>
      <LoginCheck />

      <div className="col-lg-2 col-8 d-flex mb-2 ms-auto  mb-md-0 fm-searchbar">
        <div className="col-1 d-flex align-items-center" align="center">
          &nbsp;
        </div>

        <div className="col-1 d-flex align-items-center" align="center">
          &nbsp;
        </div>

        <div
          className="col-5 d-flex align-items-center close-btn topbar-links"
          align="center"
          title={t("GENERAL.LOG_OUT")}
          onClick={() => logoutUser()}
        >
          <i className="bi bi-box-arrow-right topbar-icons"></i> &nbsp; {t("GENERAL.LOG_OUT")}
        </div>
        <div className="col-1 d-flex align-items-center" align="center">
          &nbsp;
        </div>
        <div
          className="col-5 d-flex align-items-center language-icon  topbar-links capitalized"
          align="center"
          title={t("GENERAL.LANGUAGE")}
          onClick={() => changeLanguage(currLang)}
        >
          <i className="bi bi-globe2 topbar-icons"></i> &nbsp; {currLang}
        </div>
      </div>
    </>
  )
}
export default Topbar
