import httpClient from "../utils/httpClientSetup"

class PromptService {
  savePrompt(assistantID, type, prompt, organizationID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        prompt_type: type,
        prompt: JSON.stringify(prompt),
        assistant_id: assistantID,
        organization_id: organizationID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "saveprompt",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  getAssistantQuestions(assistantID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { assistant_id: assistantID },
      url: process.env.REACT_APP_API_SERVER_URL + "assistantquestions",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  editAssistantQuestion(assistantID, quesiton, help, questionID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        question: quesiton,
        help: help,
        assistant_id: assistantID,
        id: questionID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "editassistantquestion",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  addAssistantQuestion(assistantID, quesiton, help, type) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        question: quesiton,
        help: help,
        assistant_id: assistantID,
        type: type,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "addassistantquestion",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  deleteAssistantQuestion(questionID) {
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: questionID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "deleteassistantquestion",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  getAnswersByQuestion(questionID, organizationID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: { question_id: questionID, organization_id: organizationID },
      url: process.env.REACT_APP_API_SERVER_URL + "answersbyquestion",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  updateAnswers(assistantID, organizationID, answerData, questionsList) {
    const answerArray = Object.entries(answerData).map(([key, value]) => ({
      questionId: Number(key),
      answers: value,
    }))
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        assistant_id: assistantID,
        organization_id: organizationID,
        answerdata: JSON.stringify(answerArray),
        questionslist: JSON.stringify(questionsList),
      },
      url: process.env.REACT_APP_API_SERVER_URL + "updateanswers",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  addSystemDefinedPrompt(organizationID, assistantID, prompt) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        prompt: JSON.stringify(prompt),
        assistant_id: assistantID,
        organization_id: organizationID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "addsystemprompt",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  editSystemDefinedPrompt(organizationID, assistantID, prompt, indexKey) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        index_key: indexKey,
        prompt: JSON.stringify(prompt),
        assistant_id: assistantID,
        organization_id: organizationID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "editsystemprompt",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  deleteSystemDefinedPrompt(assistantID, indexKey) {
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        index_key: indexKey,
        assistant_id: assistantID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "deletesystempromptquestion",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  getPromptByType(assistantID, type, organizationID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        prompt_type: type,
        assistant_id: assistantID,
        organization_id: organizationID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "getprompt",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  promptHistory(assistantID, orgID, page) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        assistant_id: assistantID,
        organization_id: orgID,
        page_number: page,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "prompthistory",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  fetchGroupData(assistantID, orgID, groupID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        assistant_id: assistantID,
        organization_id: orgID,
        group: groupID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "promptgroupdata",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }
  promptRestore(groupID, assistantID, orgID) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        group: groupID,
        assistant_id: assistantID,
        organization_id: orgID,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "promptrestore",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }
}
const promptService = new PromptService()
export default promptService
