import React, { useState } from "react"
import AuthService from "../services/AuthService"

function Register() {
  const [registerMessage, setRegisterMessage] = useState(null)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  })
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setRegisterMessage("")
    AuthService.register(formData).then(
      (response) => {
        setRegisterMessage(response.data.message)
      },
      (error) => {
        setRegisterMessage(error.response.data.error)
      }
    )
  }
  return (
    <div align="center">
      <h1>Register</h1>
      <form onSubmit={handleSubmit}>
        <div>
          Name: <input type="text" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div>
          Email: <input type="text" name="email" value={formData.email} onChange={handleChange} />
        </div>
        <div>
          Password: <input type="password" name="password" value={formData.password} onChange={handleChange} />
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
      <div>{registerMessage && registerMessage}</div>
    </div>
  )
}
export default Register
