import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/translations.en.json";
import norTranslations from "./locales/translations.no.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    nor: {
      translation: norTranslations,
    },
  },
  lng: "nor", // Default language
  fallbackLng: "nor",
  interpolation: {
    escapeValue: false, // React already escapes values to prevent XSS
  },
});

export default i18n;
