import React, { useEffect, useState } from "react"
import ProductService from "../services/ProductService"
import AssistantService from "../services/AssistantService"
import CreditBar from "./CreditBar"
import TopBar from "./Topbar"
import ThumbUpBlank from "../images/thumb-up-blank.png"
import ThumbUp from "../images/thumb-up.png"
import Delete from "../images/delete.png"
import CloseIcon from "../images/close.png"
import { useTranslation } from "react-i18next"
import { useParsedAssistantIdFromParams } from "../utils/utils"

function Training() {
  const { assistantID } = useParsedAssistantIdFromParams()
  const { t } = useTranslation()
  const [loadingMsg, setLoadingMsg] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState("")
  const [productList, setProductList] = useState("")
  const [toggleState, setToggleState] = useState({})
  const [additionalData, setAdditionalData] = useState({})
  const [selectedAsExample, setSelectedAsExample] = useState({})
  const organizationID = parseInt(window.sessionStorage.getItem("organization_id"), 10)
  const [showModal, setShowModal] = useState(false)
  const [showDescModal, setShowDescModal] = useState(false)
  const [productDescription, setProductDescription] = useState()
  const [totalCredit, setTotalCredit] = useState()
  const [creditUsed, setCreditUsed] = useState()
  const [trainingDataCount, setTrainingDataCount] = useState(0)

  useEffect(() => {
    setLoadingMsg(true)
    ProductService.exampleList(assistantID, organizationID).then((response) => {
      if (!response.data) {
        return []
      }
      const newOutputIds = response.data.map((item) => item.id)
      setSelectedAsExample((prevData) => {
        const newData = { ...prevData }
        newOutputIds.forEach((outputId) => {
          newData[outputId] = outputId
        })
        return newData
      })
      ProductService.productList(assistantID, "exampled", "", currentPage, organizationID)
        .then((response) => {
          setProductList(response.data.products)
          setTrainingDataCount(response.data.total_products)
          setTotalPages(response.data.total_pages)
          response.data.products.forEach((book) => {
            fetchAdditionalData(assistantID, book.product_isbn)
          })
          setLoadingMsg(false)
        })
        .catch(() => {
          setLoadingMsg(false)
        })
    })

    AssistantService.getConsumedCredits(assistantID, organizationID).then((assistresponse) => {
      setTotalCredit(assistresponse.credits)
      setCreditUsed(assistresponse.consumed)
    })
  }, [organizationID, assistantID, currentPage])

  const deleteOutput = async (outputId) => {
    const confirmed = window.confirm(t("CONFIRM_WINDOW.DELETE_ITEM"))
    if (confirmed) {
      await ProductService.deleteOutput(outputId).then(() => {
        document.getElementById("outputitem_" + outputId).style.display = "none"
      })
    }
  }

  const changeOutputExampleStatus = async (shouldUseAsExample, outputId) => {
    var confirmed
    if (shouldUseAsExample) {
      confirmed = window.confirm(t("CONFIRM_WINDOW.ADD_TRAINING_DATA"))
    } else {
      confirmed = window.confirm(t("CONFIRM_WINDOW.REMOVE_TRAINING_DATA"))
    }
    if (confirmed) {
      await ProductService.changeOutputExampleStatus(outputId).then(() => {
        shouldUseAsExample
          ? setSelectedAsExample((prevData) => ({
              ...prevData,
              [outputId]: outputId,
            }))
          : setSelectedAsExample((prevData) => {
              const { [outputId]: _, ...newData } = prevData
              return newData
            })
      })
    }
  }

  const fetchAdditionalData = async (assistantID, isbn) => {
    try {
      ProductService.outputList(assistantID, isbn, "exampled").then((response) => {
        setAdditionalData((prevData) => ({
          ...prevData,
          [isbn]: response.data,
        }))
      })
    } catch (error) {}
  }

  const showOutputs = (isbn) => {
    setToggleState((prevState) => ({
      ...prevState,
      [isbn]: !prevState[isbn],
    }))
  }

  const viewProductDescription = (prodDesc) => {
    setProductDescription(prodDesc)
    setShowDescModal(true)
  }

  const [jobDescription, setJobDescription] = useState([])
  const viewJobDescription = (outputid) => {
    const promptData = JSON.parse(outputid)
    setJobDescription(promptData)
    handleOpenModal()
  }
  const handleCloseProdDesc = () => {
    setShowDescModal(false)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handlePageClick = (page) => {
    setCurrentPage(page)
  }

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleFirstClick = () => {
    setCurrentPage(1)
  }

  const handleLastClick = () => {
    setCurrentPage(totalPages)
  }

  const generatePageNumbers = () => {
    const pageNumbers = []
    const maxPagesToShow = 7
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2)

    let startPage = Math.max(1, currentPage - halfMaxPagesToShow)
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow)

    if (currentPage - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalPages, endPage + (halfMaxPagesToShow - (currentPage - 1)))
    }

    if (currentPage + halfMaxPagesToShow > totalPages) {
      startPage = Math.max(1, startPage - (currentPage + halfMaxPagesToShow - totalPages))
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }

    return pageNumbers
  }

  const nl2br = (text) => {
    return text.split("\n").map((str) => (
      <>
        {str}
        <br />
      </>
    ))
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3  mb-1">
              <div className="col-md-3 col-lg-7 px-4 ">
                <h1 className="h1">{t("TRAINING.TRAINING")}</h1>
                <div className="breadcrumb">{t("TRAINING.TRAINING")}</div>
              </div>
              <CreditBar creditUsed={creditUsed} totalCredit={totalCredit} />
              <TopBar />
            </div>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
              <div className="col-md-3 col-lg-8 px-4 ">
                <p>{t("TRAINING.SUBLINE")}</p>
              </div>
            </div>

            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-12 navigateButton">
                  <div className="" align="right">
                    <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link  active"
                          id="pills-completed-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-completed"
                          type="button"
                          role="tab"
                          aria-controls="pills-completed"
                          aria-selected="false"
                        >
                          {t("TRAINING.TRAINING")} ({trainingDataCount})
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 fm-content-box pt-3 px-1">
                  <div className="col-12 table-responsive fm-table">
                    <div className="container-fluid pt-2 px-md-2 px-1">
                      <div className="">
                        <div className="float-start training-page-col-1 table-col-heading">&nbsp;</div>
                        <div className="float-start training-page-col-2 table-col-heading">SR.</div>
                        <div className="float-start training-page-col-3 table-col-heading">{t("TASKS.ISBN")}</div>
                        <div className="float-start training-page-col-4 table-col-heading">{t("TASKS.TITLE")}</div>
                        <div className="float-start training-page-col-5 table-col-heading">
                          {t("TRAINING.SEO_OPTIMIZED_TEXT")}
                        </div>
                        <div className="float-end text-end training-page-col-6 table-col-heading">
                          {t("GENERAL.ACTION")}
                        </div>
                      </div>
                      <div className="">
                        <div className="float-start">
                          {loadingMsg && <div className="text-center w-100">{t("GENERAL.LOADING_DATA")}</div>}
                          {productList &&
                            productList.map((val) => {
                              return (
                                <>
                                  <div
                                    className="float-start pt-2 pb-2 w-100"
                                    style={{
                                      borderTop: "1px solid #0402020f",
                                    }}
                                  >
                                    <div className="float-start float-start training-page-col-1">
                                      {additionalData[val.product_isbn] != null ? (
                                        <>
                                          <div
                                            className="plus-minus"
                                            onClick={() => showOutputs(val.product_isbn)}
                                            width="12"
                                          >
                                            {toggleState[val.product_isbn] ? "-" : "+"}{" "}
                                          </div>
                                        </>
                                      ) : (
                                        <div>&nbsp;</div>
                                      )}
                                    </div>
                                    <div className="float-start training-page-col-2">{val.serial_number}</div>
                                    <div className="float-start training-page-col-3">{val.product_isbn}</div>
                                    <div className="float-start training-page-col-4">{val.product_name}</div>
                                    <div className="float-start training-page-col-5">
                                      {nl2br(val.output)}
                                      <div style={{ clear: "both" }}></div>
                                      <div
                                        id={val.product_isbn}
                                        style={{
                                          display: toggleState[val.product_isbn] ? "block" : "none",
                                        }}
                                      >
                                        {additionalData[val.product_isbn] != null && (
                                          <>
                                            <br />
                                            <div>
                                              <b>{t("outputhistory")}</b>
                                            </div>
                                            {additionalData[val.product_isbn] &&
                                              additionalData[val.product_isbn].map((value) => {
                                                return (
                                                  <>
                                                    <div className="outputListItem" id={"outputitem_" + value.id}>
                                                      <div className="outputListItemOutput">{nl2br(value.output)}</div>
                                                      <div className="outputListItemAction">
                                                        <i
                                                          className="bi bi-card-list topbar-icons"
                                                          onClick={() =>
                                                            viewJobDescription(value.prompt, value.prompt2)
                                                          }
                                                          alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                                          title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                                        ></i>
                                                        &nbsp;.&nbsp;
                                                        {selectedAsExample[value.id] ? (
                                                          <i
                                                            class="bi bi-hand-thumbs-up-fill topbar-icons  thumbs-icon"
                                                            onClick={() => changeOutputExampleStatus(false, value.id)}
                                                            src={ThumbUp}
                                                            title={t("TASKS.REMOVE_EXAMPLE")}
                                                          ></i>
                                                        ) : (
                                                          <i
                                                            class="bi bi-hand-thumbs-up topbar-icons  thumbs-icon"
                                                            onClick={() => changeOutputExampleStatus(true, value.id)}
                                                            src={ThumbUp}
                                                            title={t("TASKS.SET_EXAMPLE")}
                                                          ></i>
                                                        )}
                                                      </div>
                                                      <div className="outputListItemAction">&nbsp;</div>
                                                    </div>
                                                  </>
                                                )
                                              })}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="float-end text-end training-page-col-6">
                                      <i
                                        className="bi bi-info-circle topbar-icons"
                                        onClick={() => viewProductDescription(val.product_description)}
                                        title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                        width="18"
                                      />
                                      &nbsp;.&nbsp;
                                      <i
                                        className="bi bi-card-list topbar-icons"
                                        onClick={() => viewJobDescription(val.prompt, val.prompt2)}
                                        alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                        title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                      ></i>
                                      &nbsp;.&nbsp;
                                      {selectedAsExample[val.id] ? (
                                        <img
                                          alt={t("TASKS.REMOVE_EXAMPLE")}
                                          className="close-btn"
                                          onClick={() => changeOutputExampleStatus(false, val.id)}
                                          src={ThumbUp}
                                          title={t("TASKS.REMOVE_EXAMPLE")}
                                          width="18"
                                        />
                                      ) : (
                                        <i
                                          class="bi bi-hand-thumbs-up topbar-icons thumbs-icon"
                                          onClick={() => changeOutputExampleStatus(true, val.id)}
                                          src={ThumbUpBlank}
                                          alt={t("TASKS.SET_EXAMPLE")}
                                          title={t("TASKS.SET_EXAMPLE")}
                                          width="18"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="float-end d-block d-sm-none text-end pb-2">
                                    <i
                                      className="bi bi-journals topbar-icons"
                                      onClick={() => viewProductDescription(nl2br(val.output))}
                                      title={t("TRAINING.SEO_OPTIMIZED_TEXT")}
                                    ></i>
                                    &nbsp;.&nbsp;
                                    <i
                                      className="bi bi-info-circle topbar-icons"
                                      onClick={() => viewProductDescription(val.product_description)}
                                      title={t("JOBS.ORIGINAL_TEXT")}
                                    ></i>
                                    &nbsp;.&nbsp;
                                    <i
                                      className="bi bi-card-list topbar-icons"
                                      onClick={() => viewJobDescription(val.prompt, val.prompt2)}
                                      title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                    ></i>
                                    &nbsp;.&nbsp;
                                    {selectedAsExample[val.id] ? (
                                      <i
                                        class="bi bi-hand-thumbs-up-fill topbar-icons  thumbs-icon"
                                        onClick={() => changeOutputExampleStatus(false, val.id)}
                                        src={ThumbUp}
                                        title={t("TASKS.REMOVE_EXAMPLE")}
                                      ></i>
                                    ) : (
                                      <i
                                        class="bi bi-hand-thumbs-up topbar-icons thumbs-icon"
                                        onClick={() => changeOutputExampleStatus(true, val.id)}
                                        src={ThumbUpBlank}
                                        alt={t("TASKS.SET_EXAMPLE")}
                                        title={t("TASKS.SET_EXAMPLE")}
                                      ></i>
                                    )}
                                  </div>
                                  <div
                                    className="d-sm-none"
                                    id={val.product_isbn}
                                    style={{
                                      display: toggleState[val.product_isbn] ? "block" : "none",
                                    }}
                                  >
                                    {additionalData[val.product_isbn] != null && (
                                      <>
                                        <div class="clearfix"></div>
                                        <div>
                                          <b>{t("TASKS.OUTPUT_HISTORY")}</b>
                                        </div>
                                        {additionalData[val.product_isbn] &&
                                          additionalData[val.product_isbn].map((value) => {
                                            return (
                                              <>
                                                <div className="outputListItem" id={"outputitem_" + value.id}>
                                                  <div className="outputListItemOutput">{nl2br(value.output)}</div>
                                                  <div className="outputListItemAction">
                                                    <i
                                                      className="bi bi-card-list topbar-icons"
                                                      onClick={() => viewJobDescription(value.prompt, value.prompt2)}
                                                      alt={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                                      title={t("TASKS.VIEW_JOB_DESCRIPTION")}
                                                    ></i>
                                                    &nbsp;.&nbsp;
                                                    <img
                                                      className="close-btn"
                                                      style={{
                                                        display: "none",
                                                      }}
                                                      src={Delete}
                                                      title={t("GENERAL.DELETE")}
                                                      alt={t("GENERAL.DELETE")}
                                                      onClick={() => deleteOutput(value.id)}
                                                      width="18"
                                                    />
                                                    {selectedAsExample[value.id] ? (
                                                      <i
                                                        class="bi bi-hand-thumbs-up-fill topbar-icons  thumbs-icon"
                                                        onClick={() => changeOutputExampleStatus(false, value.id)}
                                                        alt={t("TASKS.REMOVE_EXAMPLE")}
                                                        title={t("TASKS.REMOVE_EXAMPLE")}
                                                      ></i>
                                                    ) : (
                                                      <i
                                                        class="bi bi-hand-thumbs-up topbar-icons  thumbs-icon"
                                                        onClick={() => changeOutputExampleStatus(true, value.id)}
                                                        alt={t("TASKS.SET_EXAMPLE")}
                                                        title={t("TASKS.SET_EXAMPLE")}
                                                      ></i>
                                                    )}
                                                  </div>
                                                  <div className="outputListItemAction">&nbsp;</div>
                                                </div>
                                              </>
                                            )
                                          })}
                                      </>
                                    )}
                                  </div>
                                  <div class="clearfix"></div>
                                </>
                              )
                            })}
                        </div>
                      </div>

                      {totalPages > 1 && (
                        <>
                          <div className="clearfix float-start col-12 paging-container">
                            <button className="paging" onClick={handleFirstClick} disabled={currentPage === 1}>
                              {t("GENERAL.FIRST")}
                            </button>
                            <button className="paging" onClick={handlePreviousClick} disabled={currentPage === 1}>
                              {t("GENERAL.PREVIOUS")}
                            </button>
                            {generatePageNumbers().map((page) => (
                              <button
                                className={currentPage === page ? "currpaging" : "paging"}
                                key={page}
                                onClick={() => handlePageClick(page)}
                              >
                                {page}
                              </button>
                            ))}
                            <button className="paging" onClick={handleNextClick} disabled={currentPage === totalPages}>
                              {t("GENERAL.NEXT")}
                            </button>
                            <button className="paging" onClick={handleLastClick} disabled={currentPage === totalPages}>
                              {t("GENERAL.LAST")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div class="clearfix">
                  <br />
                  <br />
                </div>
              </div>
              {showDescModal && (
                <div className="modal display-block">
                  <div className="modal-main">
                    <div
                      style={{
                        float: "right",
                        marginTop: "-15px",
                        marginRight: "-5px",
                      }}
                    >
                      <img alt="close" src={CloseIcon} onClick={handleCloseProdDesc} className="close-btn" width="12" />
                    </div>
                    <div className="modal-contentt">{productDescription && productDescription}</div>
                  </div>
                </div>
              )}
              {showModal && (
                <div className="modal display-block">
                  <div className="modal-main">
                    <div
                      style={{
                        float: "right",
                        marginTop: "-15px",
                        marginRight: "-5px",
                      }}
                    >
                      <img
                        src={CloseIcon}
                        onClick={handleClose}
                        alt={t("GENERAL.CLOSE")}
                        className="close-btn"
                        width="12"
                      />
                    </div>
                    <div className="modal-contentt">
                      {jobDescription &&
                        jobDescription.map((value) => {
                          return (
                            <>
                              <table className="table table-hover align-middle">
                                <tbody>
                                  <tr>
                                    <td colspan="2">
                                      <b>{value.question}</b>
                                    </td>
                                  </tr>
                                  {value.answers &&
                                    value.answers.map((ans) => {
                                      return (
                                        <>
                                          <tr>
                                            {ans.answer_heading && (
                                              <td className="col-4">
                                                <input
                                                  readonly="true"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  aria-label=""
                                                  aria-describedby=""
                                                  value={ans.answer_heading}
                                                />
                                              </td>
                                            )}
                                            <td>
                                              <input
                                                readonly="true"
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                aria-label=""
                                                aria-describedby=""
                                                value={ans.answer}
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    })}
                                </tbody>
                              </table>
                            </>
                          )
                        })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default Training
