import { NavLink } from "react-router-dom"
import React, { useEffect, useState } from "react"
import UserService from "../services/UserService"
import TopBar from "./Topbar"
import { useTranslation } from "react-i18next"
function Organizations() {
  const [organizationList, setOrganizationList] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [totalProgressPages, setTotalProgressPages] = useState(1)
  const { t } = useTranslation()
  useEffect(() => {
    UserService.getOrganizationList().then((response) => {
      setOrganizationList(response.data.organizations)
      setTotalProgressPages(1)
    })
  }, [])

  const handlePageClick = (page) => {
    setPageNumber(page)
  }

  const handleNextClick = () => {
    if (pageNumber < totalProgressPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const handlePreviousClick = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const handleFirstClick = () => {
    setPageNumber(1)
  }

  const handleLastClick = () => {
    setPageNumber(totalProgressPages)
  }
  const generatePageNumbers = () => {
    const pageNumbers = []
    const maxPagesToShow = 7
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2)
    let startPage = Math.max(1, pageNumber - halfMaxPagesToShow)
    let endPage = Math.min(totalProgressPages, pageNumber + halfMaxPagesToShow)

    if (pageNumber - halfMaxPagesToShow < 1) {
      endPage = Math.min(totalProgressPages, endPage + (halfMaxPagesToShow - (pageNumber - 1)))
    }

    if (pageNumber + halfMaxPagesToShow > totalProgressPages) {
      startPage = Math.max(1, startPage - (pageNumber + halfMaxPagesToShow - totalProgressPages))
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }
    return pageNumbers
  }
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
              <div className="col-md-3 col-lg-7  col-12 px-4 ">
                <h1 className="h1">{t("GENERAL.ORGANIZATIONS")}</h1>
                <div className="breadcrumb">{t("GENERAL.ORGANIZATIONS")}</div>
              </div>
              <div className="col-md-3 col-lg-3 p-4 ">&nbsp;</div>
              <TopBar />
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <NavLink to={"/add-organization"}>
                    <button className="btn btn-md fm-btn me-md-2" type="button">
                      {t("ORGANIZATIONS.ADD")}
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-4">
              <div className="row tools">
                <div className="col-12 fm-content-box p-4">
                  <div className="col-12 table-responsive mt-4 fm-table">
                    <table className="table table-hover align-top">
                      <tbody>
                        <tr>
                          <th scope="col">SR.</th>
                          <th scope="col">{t("GENERAL.NAME")}</th>
                          <th scope="col" className="col-1">
                            {t("GENERAL.ACTION")}
                          </th>
                        </tr>
                        {organizationList &&
                          organizationList.map((value) => {
                            return (
                              <>
                                <tr>
                                  <td>{value.serial_number}</td>
                                  <td>{value.name}</td>
                                  <td>
                                    <i
                                      alt={t("GENERAL.DELETE")}
                                      title={t("GENERAL.DELETE")}
                                      className="bi bi-trash topbar-icons"
                                    ></i>
                                    &nbsp;.&nbsp;
                                    <i
                                      alt={t("GENERAL.EDIT")}
                                      title={t("GENERAL.EDIT")}
                                      className="bi bi-pencil topbar-icons"
                                    ></i>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                      <tr>
                        <td colspan="5">
                          <button className="paging" onClick={handleFirstClick} disabled={pageNumber === 1}>
                            {t("GENERAL.FIRST")}
                          </button>
                          <button className="paging" onClick={handlePreviousClick} disabled={pageNumber === 1}>
                            {t("GENERAL.PREVIOUS")}
                          </button>
                          {generatePageNumbers().map((page) => (
                            <button
                              className={pageNumber === page ? "currpaging" : "paging"}
                              key={page}
                              onClick={() => handlePageClick(page)}
                            >
                              {page}
                            </button>
                          ))}
                          <button
                            className="paging"
                            onClick={handleNextClick}
                            disabled={pageNumber === totalProgressPages}
                          >
                            {t("GENERAL.NEXT")}
                          </button>
                          <button
                            className="paging"
                            onClick={handleLastClick}
                            disabled={pageNumber === totalProgressPages}
                          >
                            {t("GENERAL.LAST")}
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default Organizations
