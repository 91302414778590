import React from "react"

const MyLoader = () => {
  return (
    <svg version="1.1" viewBox="0 0 400 130" preserveAspectRatio="xMidYMid meet">
      <rect
        x="0"
        y="0"
        style={{ fill: "url(#bvf8gq2tr4)" }}
        clipPath="url(#3d394h6ixre)"
        width="400"
        height="130"
      ></rect>
      <defs>
        <clipPath id="3d394h6ixre">
          <rect height="20" rx="3" ry="3" width="100" x="0" y="0"></rect>
          <rect height="20" rx="3" ry="3" width="290" x="110" y="0"></rect>
          <rect height="20" rx="3" ry="3" width="100" x="0" y="30"></rect>
          <rect height="20" rx="3" ry="3" width="290" x="110" y="30"></rect>
          <rect height="20" rx="3" ry="3" width="100" x="0" y="60"></rect>
          <rect height="20" rx="3" ry="3" width="290" x="110" y="60"></rect>
          <rect height="20" rx="3" ry="3" width="100" x="0" y="90"></rect>
          <rect height="20" rx="3" ry="3" width="290" x="110" y="90"></rect>
        </clipPath>
        <linearGradient id="bvf8gq2tr4">
          <stop offset="0%" stopColor="#EDECEC">
            <animate attributeName="offset" repeatCount="indefinite" values="-2; 1" dur="2s"></animate>
          </stop>
          <stop offset="50%" stopColor="#E2E0E0">
            <animate attributeName="offset" repeatCount="indefinite" values="-1.5; 1.5" dur="2s"></animate>
          </stop>
          <stop offset="100%" stopColor="#EDECEC">
            <animate attributeName="offset" repeatCount="indefinite" values="-1; 2" dur="2s"></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MyLoader
