import { Link } from "react-router-dom"
import React from "react"
import TopBar from "./Topbar"
import OurValues from "../images/feed.png"
import { useTranslation } from "react-i18next"

function Tools() {
  const { t } = useTranslation()
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-lg-none d-block align-items-center pt-3 pb-2 mb-3">
              <button
                className="navbar-toggler bg-light position-absolute d-md-none collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
              <div className="col-md-3 col-lg-7  col-12 px-4 ">
                <h1 className="h1">{t("GENERAL.TOOLS")}</h1>
                <div className="breadcrumb">{t("GENERAL.TOOLS")}</div>
              </div>
              <div className="col-md-3 col-lg-3 p-4 ">&nbsp;</div>
              <TopBar />
            </div>

            <div className="container-fluid">
              <div className="row tools">
                <Link className="nav-link" to={"/tools/pim"}>
                  <div className="fm-top-box-tools col-12 col-lg-2 m-2 p-6" align="center">
                    <img src={OurValues} width="140" alt={t("DASHBOARD.OUR_VALUES")} />
                  </div>
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
export default Tools
