import httpClient from "../utils/httpClientSetup"

class UserService {
  getUserFromToken(access_token) {
    const url = `${process.env.REACT_APP_API_SERVER_URL}getuser`
    return httpClient
      .post(
        url,
        { email: access_token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error("Error fetching user from token:", error)
        throw error
      })
  }
  saveOutputHistory(isbn, output, assistantID, organizationID, answerData, questionsList) {
    const answerArray = Object.entries(answerData).map(([key, value]) => ({
      questionId: Number(key),
      answers: value,
    }))
    const data = {
      isbn: isbn,
      output: output,
      assistant_id: assistantID,
      organization_id: organizationID,
      answerdata: JSON.stringify(answerArray),
      questionlist: JSON.stringify(questionsList),
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
      url: process.env.REACT_APP_API_SERVER_URL + "saveoutputhistory",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  execution(orgID, userID, assistantID, isbn, answerData, questionList) {
    const answerArray = Object.entries(answerData).map(([key, value]) => ({
      questionId: Number(key),
      answers: value,
    }))
    const data = {
      isbn: isbn,
      assistant_id: assistantID,
      organization_id: orgID,
      user_id: userID,
      answerdata: JSON.stringify(answerArray),
      questionlist: JSON.stringify(questionList),
    }

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data,
      url: process.env.REACT_APP_API_SERVER_URL + "execute",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  getOrganizationList() {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API_SERVER_URL + "organizationlist",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  addOrganization(fields, assignedAssistant) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name: fields.organization_name,
        description: fields.organization_description,
        assistant_id: JSON.stringify(assignedAssistant),
      },
      url: process.env.REACT_APP_API_SERVER_URL + "addorganization",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  getUserList(orgID) {
    const data = {
      organization_id: orgID,
    }
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data,
      url: process.env.REACT_APP_API_SERVER_URL + "userlist",
    }
    return httpClient(options).then((response) => {
      return response
    })
  }

  addUser(fields) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name: fields.user_name,
        email: fields.user_email,
        organization_id: 1,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "adduser",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }

  deletUserById(userId) {
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: userId,
      },
      url: process.env.REACT_APP_API_SERVER_URL + "deleteuserbyid",
    }
    return httpClient(options).then((response) => {
      return response.data
    })
  }
}
const userServiceInstance = new UserService()

export default userServiceInstance
